/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    string, shape, bool, number,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getFeatureFlags, getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTests } from '../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const useStyles = makeStyles(({
    productImage: {
        display: 'block',
        width: 'inherit',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
}));

const ProductImage = ({
    image, name, absoluteImagePath, positionInCategory,
}) => {
    const classes = useStyles();
    const presentationFamily = useSelector(getPresentationFamily);
    const isFood = presentationFamily === 'food';
    const featureFlags = useSelector(getFeatureFlags);
    const additionalImageArray = image?.additionalImages?.length > 0;
    const activeABTests = useSelector(getActiveABTests);

    const productImageLoading = () => (positionInCategory < 4 ? 'eager' : 'lazy');

    // REDO ALL THIS TO JUST PULL IN PJPEG FROM FASTLY?
    // url of the current image in the card
    // Must have complex expression to render correctly on server (useEffect does not run)
    const generateCurrentImageSrc = () => {
        if (image?.collectionImage && image?.collectionImage !== '') {
            return (`${image?.path}${image.collectionImage}x.jpg?height=456&width=418&sharpen=a0.5,r1,t1&auto=webp`);
        }
        return image?.fullPath
            ? (`${image.fullPath}?height=456&width=418&sharpen=a0.5,r1,t1&auto=webp`)
            : (`${absoluteImagePath ? image : `${image?.path}${image?.name}x.jpg`}?height=456&width=418&sharpen=a0.5,r1,t1&auto=webp`);
    };
    /**
     *
     *
     * FOR ABTEST ALT IMAGE HOVER
     * REMOVE IF TEST IS NO LONGER NEEDED/USED
     *
     *
     */
    const [hoverImgSrc, setHoverImgSrc] = useState();
    const [hovering, setHovering] = useState(false);

    useEffect(() => {
        // Set the hover image
        setHoverImgSrc(`${image.path}${image?.additionalImages?.[0]}x.jpg?height=378&width=345&sharpen=a0.5,r1,t1&auto=webp`);
    }, [image]);

    if (!isFood
            && featureFlags['is-alt-image-hover-enabled']
            && additionalImageArray
            && activeABTests?.alt_image_hover_enabled === 'VARIANT') {
        return (
            <img
                // This src is a temp solution until a brand specific more complete url comes from the back end.
                src={hovering ? hoverImgSrc : generateCurrentImageSrc()}
                alt={name}
                className={classes.productImage}
                onMouseOver={() => { setHovering(true); }}
                onFocus={() => { setHovering(true); }}
                onMouseOut={() => { setHovering(false); }}
                onBlur={() => { setHovering(false); }}
                loading={productImageLoading()}
                style={{ borderRadius: '50%' }}
            />
        );
    }
    /**
     *
     *
     * END ABTEST ALT IMAGE HOVER
     * END REMOVE ALL CODE UP TO HERE IF NO LONGER NEEDED/USED
     *
     *
     */
    return (
        <img
            // This src is a temp solution until a brand specific more complete url comes from the back end.
            src={generateCurrentImageSrc()}
            alt={name}
            className={classes.productImage}
            loading={productImageLoading()}
        />
    );
};

ProductImage.propTypes = {
    name: string.isRequired,
    image: shape({
        name: string.isRequired,
        path: string.isRequired,
        fullPath: string.isRequired,

    }).isRequired,
    absoluteImagePath: bool,
    positionInCategory: number,
};

ProductImage.defaultProps = {
    absoluteImagePath: false,
    positionInCategory: 0,
};

export default ProductImage;
