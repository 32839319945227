/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    array,
    bool,
    func,
    number, object,
    string,
} from 'prop-types';

import ProductUI from './ProductUI';
import noop from '../../../../../../helpers/noop';

const DesktopUIContainer = ({
    minRowsToShow, trackClickThrough, recentlyViewedSectionBGColor, productClickCallback, productStyleConfig, products,
}) => {
    const [rowCount, setRowCount] = useState(minRowsToShow);
    const [isExpanded, setIsExpanded] = useState(false);

    const {
        show_nested_title, number_of_products_per_row,
    } = productStyleConfig || {};

    const PRODUCT_TO_SHOW_PER_ROW = show_nested_title ? 4 : parseInt(number_of_products_per_row, 10);

    const totalProductsRows = Math.ceil(products?.length > 0 ? ((products?.length) / PRODUCT_TO_SHOW_PER_ROW) : 1);

    const maxRowsAllowed = Math.min(totalProductsRows, show_nested_title ? 4 : 2);

    const handleSeeMore = () => {
        const newRowCount = rowCount + 1;
        if (newRowCount >= maxRowsAllowed) {
            setIsExpanded(true);
        }
        setRowCount(newRowCount);
    };

    const handleSeeLess = () => {
        setIsExpanded(false);
        setRowCount(minRowsToShow);
    };

    const maxProductsToShow = Math.min(rowCount, maxRowsAllowed) * PRODUCT_TO_SHOW_PER_ROW;

    return (
        <>
            <ProductUI
                slicedProducts={products?.slice(0, maxProductsToShow)}
                maxRowsAllowed={maxRowsAllowed}
                minRowsToShow={minRowsToShow}
                handleSeeLess={handleSeeLess}
                handleSeeMore={handleSeeMore}
                handleLinkOnClick={trackClickThrough}
                isExpanded={isExpanded}
                showFullGrid={PRODUCT_TO_SHOW_PER_ROW === 6}
                containerBGColor={recentlyViewedSectionBGColor}
                demandType="XS"
                productClickCallback={productClickCallback}
                productStyleConfig={productStyleConfig}
            />
        </>

    );
};

DesktopUIContainer.propTypes = {
    minRowsToShow: number,
    trackClickThrough: func,
    recentlyViewedSectionBGColor: string,
    productClickCallback: func,
    productStyleConfig: object,
    products: object.isRequired,
};

DesktopUIContainer.defaultProps = {
    minRowsToShow: 1,
    trackClickThrough: noop,
    recentlyViewedSectionBGColor: '',
    productClickCallback: noop,
    productStyleConfig: {},
};

export default DesktopUIContainer;
