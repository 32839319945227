/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { object, string, func } from 'prop-types';
import ReactHTMLParser from 'react-html-parser';
import { Link } from 'react-router-dom';

import { parseFont } from '../../../../../helpers/common/helper';
import BlockHeading from '../../../../ContentTypeComponents/BlockHeading';
import ProductImage from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/ProductImage';
import determineProductUrl from '../../../../../helpers/determineProductUrl';
import noop from '../../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    heading: (styles) => (
        {
            flex: '1 1 50%',
            fontFamily: parseFont(styles?.fontFamily) || 'Playfair Display',
            color: styles?.color,
            fontStyle: styles?.fontStyle,
            padding: '32px 42px 24px 42px',
            backgroundColor: styles?.backgroundColor,
            '& h2': {
                fontSize: styles?.fontSize || '32px',
            },
            '& span': {
                fontSize: styles?.fontSize1 || '18px',
                fontFamily: parseFont(styles?.fontFamily1) || theme?.typography?.fontFamily,
                color: styles?.color1,
                fontStyle: styles?.fontStyle1,
            },
            [theme.breakpoints.down(600)]: {
                padding: '0px 0px 24px 0px',
                '& h2': {
                    fontSize: styles?.fontSize || '24px',
                },
            },
        }
    ),
    headingAlignment: {
        display: 'flex',
        flex: '1 1 50%',
        '& h2': {
            margin: '0px 0px 4px 0px',
        },
    },
    seedProductContainer: {
        display: 'flex',
        gap: '12px',
        '& img': {
            width: '72px',
            height: '78px',
            position: 'static',
            [theme.breakpoints.down(600)]: {
                width: '3.875rem',
                height: '4.25rem',
            },
        },
    },
}));

export default function Heading({
    title, subTitle, styles, product, handleLinkOnClick,
}) {
    const {
        name, image, partNumber, seo,
    } = product;
    const classes = useStyles(styles);

    return (
        <div id="recentlyViewed-heading-id" className={classes.heading}>
            <div className={classes.seedProductContainer}>
                {Object.entries(product).length > 0 && (
                    <div>
                        <Link
                            data-testid={`${name}${'-'}${partNumber}`?.split(' ')?.join('-')?.toLowerCase()}
                            data-test="recommended-product"
                            title={`seed-product-${ReactHTMLParser(name)}`}
                            tabIndex="0"
                            to={determineProductUrl(seo?.url)}
                            onClick={() => handleLinkOnClick(partNumber)}
                            className={classes.product}
                            key={name}
                        >
                            <ProductImage image={image} name={name} />
                        </Link>
                    </div>
                )}
                <div>
                    <div className={classes.headingAlignment}>
                        <BlockHeading heading={ReactHTMLParser(title)} />
                    </div>
                    <span id="recentlyViewed-subTitle-id">{ReactHTMLParser(subTitle)}</span>
                </div>
            </div>
        </div>
    );
}

Heading.propTypes = {
    title: string.isRequired,
    subTitle: string.isRequired,
    styles: object,
    product: object,
    handleLinkOnClick: func,
};

Heading.defaultProps = {
    styles: {},
    product: {},
    handleLinkOnClick: noop,
};
