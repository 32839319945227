/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import {
    array, bool, func, number,
    string, object,
} from 'prop-types';
import { Height } from '@material-ui/icons';
import noop from '../../../../../../helpers/noop';
import { getPriceRangeLayout, getFeatureFlag } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import ConnectedPersonalizedHomePageProduct from '../../PersonalizedHomePageProduct/PersonalizedHomePageProduct';
import StyledButton from '../../../../../EnterpriseDesignSystem/Button/StyledButton';

const useStyles = makeStyles((theme) => ({
    container: ({ containerBGColor, showSeeMoreBtn }) => ({
        flex: '1 1 50%',
        padding: showSeeMoreBtn ? '0 0px' : '0 0px',
        // padding: showSeeMoreBtn ? '0 42px' : '0 42px 64px',
        backgroundColor: `${containerBGColor} !important`,
        [theme.breakpoints.down(600)]: {
            padding: '0px',
        },
    }),
    productContainer: ({ showFullGrid, showGridHeadlineBox, productsPerRow }) => ({
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: showFullGrid || showGridHeadlineBox ? 'repeat(6, 1fr)' : `repeat(${productsPerRow}, 1fr)`,
        [theme.breakpoints.down(600)]: {
            gap: '16px',
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    }),
    product: {
        position: 'relative',
        '& img': {
            width: '100%',
            position: 'static',
        },
    },
    priceContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        bottom: '10px',
        left: '10px',
        padding: '2px 6px',
        borderRadius: 2,
        textTransform: 'capitalize',
        fontFamily: 'Lato',
        color: 'black',
        '& span': {
            fontWeight: '700',
            fontSize: '14px',
        },
        [theme.breakpoints.down(600)]: {
            padding: '4px 6px',
        },
    },
    buttonContainer: ({ alignActionButton }) => ({
        padding: '32px 0px 32px 0px',
        textAlign: alignActionButton || 'left',
        [theme.breakpoints.down(600)]: {
            padding: '32px 0px 0px',
        },
        '& button': {
            border: '1px solid #000',
            borderRadius: '3px',
            '&:hover': {
                border: '1px solid #000',
                borderRadius: '3px',
            },
            '&:focus': {
                outline: 'none',
            },
        },
    }),
    resetPadding: {
        padding: 'unset',
        [theme.breakpoints.down(600)]: {
            width: '6.25rem',
            '& div': {
                width: '6.25rem',
            },
        },
    },
    gridTitleCard: ({ gridTitleCardBGColor, GridItemConfiguration }) => ({
        backgroundColor: gridTitleCardBGColor || '',
        padding: '16px 16px 0px 16px',
        paddingBottom: '0',
        gridColumnStart: GridItemConfiguration?.grid_item_column_start || 1,
        gridColumnEnd: GridItemConfiguration?.grid_item_column_end || 1,
        gridRowStart: GridItemConfiguration?.grid_item_row_start || 1,
        gridRowEnd: GridItemConfiguration?.grid_item_row_end || 1,
        [theme.breakpoints.down(600)]: {
            gridColumnStart: 1,
            gridColumnEnd: 2,
            gridRowStart: 1,
            gridRowEnd: 1,
        },
    }),
    gridTitle: ({ titleFontColor }) => ({
        fontFamily: theme.typography.fontFamily,
        color: titleFontColor || theme.typography.fontColor,
        fontSize: '28px',
        fontWeight: '600',
        lineHeight: '35px',
        letterSpacing: '0.01em',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        paddingBottom: '16px',
        [theme.breakpoints.down(600)]: {
            fontSize: '21px',
            lineHeight: '28px',
        },
    }),
    gridSubTitle: ({ subTitleFontColor }) => ({
        fontFamily: theme.typography.fontFamily,
        color: subTitleFontColor || theme.typography.fontColor,
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '26px',
        letterSpacing: '0.01em',
        textAlign: 'left',
        textUnderlinePosition: 'from-font',
        textDecorationSkipInk: 'none',
        paddingBottom: '16px',
        [theme.breakpoints.down(600)]: {
            fontSize: '16px',
            lineHeight: '21px',
        },
    }),
    gridActionBtn: {
        '& a': {
            border: '1px solid #000',
            borderRadius: '3px',
            '& span': {
                fontFamily: theme.typography.fontFamily,
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '24px',
                letterSpacing: '0.04em',
                textAlign: 'center',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
            },
            '&:hover': {
                border: '1px solid #000',
                borderRadius: '3px',
            },
        },
    },
}));

const ProductUI = ({
    slicedProducts, minRowsToShow, showFullGrid,
    isExpanded, handleSeeMore, handleSeeLess, maxRowsAllowed, handleLinkOnClick, containerBGColor, demandType, productClickCallback, productStyleConfig, title, subTitle, isMobile,
}) => {
    const {
        main_title, subtitle, show_nested_title, nested_grid_headline, show_subtitle, number_of_products_per_row, align_action_button, title_styling, subtitle_styling,
    } = productStyleConfig || {};

    const {
        cta_text, grid_background_color, apply_grid_background_color, cta_link, cta_html_attributes, grid_item_configuration,
    } = nested_grid_headline || {};

    const gridHeadlineBoxBGColor = grid_background_color?.color;
    const titleFontColor = title_styling?.title_font_color?.color;
    const subTitleFontColor = subtitle_styling?.font_color?.color;

    const classes = useStyles({
        showFullGrid, containerBGColor, showSeeMoreBtn: minRowsToShow < maxRowsAllowed, showGridHeadlineBox: show_nested_title, gridTitleCardBGColor: apply_grid_background_color ? gridHeadlineBoxBGColor : '', productsPerRow: number_of_products_per_row, GridItemConfiguration: grid_item_configuration, alignActionButton: align_action_button, titleFontColor, subTitleFontColor,
    });

    const renderGridTitleCard = () => (
        <div className={classes.gridTitleCard}>
            <div className={classes.gridTitle}>{main_title}</div>
            {show_subtitle && (<div className={classes.gridSubTitle}>{subtitle}</div>)}
            {!isMobile && cta_text && cta_link && (
                <div className={classes.gridActionBtn}>
                    <StyledButton
                        label={cta_text}
                        link={cta_link}
                        variant="light"
                        size="sm"
                        attributes={cta_html_attributes}
                    />
                </div>
            )}
        </div>
    );

    if (slicedProducts?.length > 0) {
        return (
            <div className={classes.container}>
                <div className={classes.productContainer}>
                    {show_nested_title && renderGridTitleCard()}
                    {slicedProducts.map((product, index) => {
                        const {
                            name, image, skuPriceRange, partNumber, seo: { url }, availability, brandId, isPassportEligible,
                        } = product;
                        const link = url || '';

                        return (
                            <>
                                <ConnectedPersonalizedHomePageProduct
                                    name={name}
                                    url={link}
                                    image={image}
                                    skuPriceRange={skuPriceRange}
                                    availability={availability}
                                    partNumber={partNumber}
                                    showSimpleProductRedesignAbTest={false}
                                    productBrand={brandId}
                                    productClickCallback={() => productClickCallback(partNumber)}
                                    productSkus={product.productSkus}
                                    eventTrackingData={{}}
                                    productStyleConfig={productStyleConfig}
                                    priceRangeLayout={productStyleConfig?.price_range_layout || []}
                                    isPassportEligible={isPassportEligible}
                                    itemIndex={index}
                                />
                            </>
                        );
                    })}
                </div>

                {
                    (minRowsToShow < maxRowsAllowed) && (
                        <div className={classes.buttonContainer}>
                            <StyledButton
                                label={isExpanded ? 'See Less' : 'See More'}
                                type="button"
                                handleOnClick={isExpanded ? handleSeeLess : handleSeeMore}
                                variant="light"
                                size="sm"
                            />
                        </div>
                    )
                }
            </div>
        );
    }

    return null;
};

ProductUI.propTypes = {
    slicedProducts: array,
    minRowsToShow: number,
    showFullGrid: bool,
    isExpanded: bool,
    handleSeeMore: func,
    handleSeeLess: func,
    handleLinkOnClick: func,
    maxRowsAllowed: number.isRequired,
    containerBGColor: string,
    demandType: string,
    productClickCallback: func,
    productStyleConfig: object,
    title: string,
    subTitle: string,
    isMobile: bool,
};

ProductUI.defaultProps = {
    slicedProducts: [],
    minRowsToShow: 1,
    showFullGrid: false,
    isExpanded: false,
    handleSeeMore: noop,
    handleSeeLess: noop,
    handleLinkOnClick: noop,
    containerBGColor: '',
    demandType: '',
    productClickCallback: noop,
    productStyleConfig: {},
    title: '',
    subTitle: '',
    isMobile: false,
};

export default ProductUI;
