/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactHTMLParser from 'react-html-parser';
import { useSelector } from 'react-redux';

import {
    array, bool, func, number,
    string,
} from 'prop-types';
import { Height } from '@material-ui/icons';
import ProductImage from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/ProductImage';
import PriceRange from '../../../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import determineProductUrl from '../../../../../helpers/determineProductUrl';
import noop from '../../../../../helpers/noop';
import { getPriceRangeLayout, getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import StyledButton from '../../../../EnterpriseDesignSystem/Button/StyledButton';

const useStyles = makeStyles((theme) => ({
    container: ({ containerBGColor, showSeeMoreBtn }) => ({
        flex: '1 1 50%',
        padding: showSeeMoreBtn ? '0 42px' : '0 42px 64px',
        backgroundColor: `${containerBGColor} !important`,
        [theme.breakpoints.down(600)]: {
            padding: '0px',
        },
    }),
    productContainer: ({ showFullGrid }) => ({
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: showFullGrid ? 'repeat(6, 1fr)' : 'repeat(3, 1fr)',
        [theme.breakpoints.down(600)]: {
            gap: '16px',
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    }),
    product: {
        position: 'relative',
        '& img': {
            width: '100%',
            position: 'static',
        },
    },
    priceContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        bottom: '10px',
        left: '10px',
        padding: '2px 6px',
        borderRadius: 2,
        textTransform: 'capitalize',
        fontFamily: 'Lato',
        color: 'black',
        '& span': {
            fontWeight: '700',
            fontSize: '14px',
        },
        [theme.breakpoints.down(600)]: {
            padding: '4px 6px',
        },
    },
    buttonContainer: {
        padding: '32px 0px',
        [theme.breakpoints.down(600)]: {
            padding: '32px 0px 0px',
        },
        '& button': {
            border: '1px solid #000',
            borderRadius: '3px',
            '&:hover': {
                border: '1px solid #000',
                borderRadius: '3px',
            },
            '&:focus': {
                outline: 'none',
            },
        },
    },
    resetPadding: {
        padding: 'unset',
        [theme.breakpoints.down(600)]: {
            width: '6.25rem',
            '& div': {
                width: '6.25rem',
            },
        },
    },
}));

const ProductUI = ({
    slicedProducts, minRowsToShow, showFullGrid,
    isExpanded, handleSeeMore, handleSeeLess, maxRowsAllowed, handleLinkOnClick, containerBGColor, productSkuPrices, getRetailPrice, getSalePrice, dynamicPriceIsLoading,
}) => {
    const classes = useStyles({ showFullGrid, containerBGColor, showSeeMoreBtn: minRowsToShow < maxRowsAllowed });
    const priceRangeLayout = useSelector(getPriceRangeLayout);
    const isDynamicPriceEnabled = useSelector((state) => getFeatureFlag('is-dynamic-pricing-enabled')(state));
    const priceRangeLayoutFromCMS = priceRangeLayout && priceRangeLayout?.length > 0 ? priceRangeLayout : [{ viewport: 'Mobile', price_layout: 'from $xxx.xx' }, { viewport: 'Desktop', price_layout: 'from $xxx.xx' }];

    if (slicedProducts?.length > 0) {
        return (
            <div className={classes.container}>
                <div className={classes.productContainer}>
                    {slicedProducts.map((product) => {
                        const {
                            name, image, skuPriceRange, partNumber, seo,
                        } = product;
                        let updatedSkuPriceRange = {};
                        if (isDynamicPriceEnabled && productSkuPrices.length > 0) {
                            const dynamicPriceList = productSkuPrices?.find?.((item) => item?.id === partNumber)?.prices?.sort((firstPriceItem, secondPriceItem) => firstPriceItem.value - secondPriceItem.value) || [];
                            if (dynamicPriceList.length > 0) {
                                updatedSkuPriceRange = {
                                    retail: [{ value: getRetailPrice(dynamicPriceList) }],
                                    sale: [{ value: getSalePrice(dynamicPriceList) }],
                                };
                            }
                        }
                        return (
                            <Link
                                data-testid={`${name}${'-'}${partNumber}`?.split(' ')?.join('-')?.toLowerCase()}
                                data-test="recommended-product"
                                title={`${ReactHTMLParser(name)}`}
                                tabIndex="0"
                                to={determineProductUrl(seo?.url)}
                                onClick={() => handleLinkOnClick(partNumber)}
                                className={classes.product}
                                key={name}
                            >
                                <ProductImage image={image} name={name} />
                                <div className={`${classes.priceContainer} ${isDynamicPriceEnabled && dynamicPriceIsLoading ? classes.resetPadding : ''}`}>
                                    <PriceRange
                                        skuPriceRange={isDynamicPriceEnabled ? updatedSkuPriceRange : skuPriceRange}
                                        priceRangeLayout={priceRangeLayoutFromCMS}
                                        displayPrice={isDynamicPriceEnabled ? !dynamicPriceIsLoading : true}
                                    />
                                </div>
                            </Link>
                        );
                    })}
                </div>

                {
                    (minRowsToShow < maxRowsAllowed) && (
                        <div className={classes.buttonContainer}>
                            <StyledButton
                                label={isExpanded ? 'See Less' : 'See More'}
                                type="button"
                                handleOnClick={isExpanded ? handleSeeLess : handleSeeMore}
                                variant="light"
                                size="sm"
                            />
                        </div>
                    )
                }
            </div>
        );
    }

    return null;
};

ProductUI.propTypes = {
    slicedProducts: array,
    minRowsToShow: number,
    showFullGrid: bool,
    isExpanded: bool,
    handleSeeMore: func,
    handleSeeLess: func,
    handleLinkOnClick: func,
    maxRowsAllowed: number.isRequired,
    containerBGColor: string,
    productSkuPrices: array,
    getRetailPrice: func,
    getSalePrice: func,
    dynamicPriceIsLoading: bool,
};

ProductUI.defaultProps = {
    slicedProducts: [],
    minRowsToShow: 1,
    showFullGrid: false,
    isExpanded: false,
    handleSeeMore: noop,
    handleSeeLess: noop,
    handleLinkOnClick: noop,
    containerBGColor: '',
    productSkuPrices: [],
    getRetailPrice: noop,
    getSalePrice: noop,
    dynamicPriceIsLoading: false,
};

export default ProductUI;
