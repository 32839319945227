/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, shape } from 'prop-types';
import mbpLogger from 'mbp-logger';

import CarouselBuilderSkeleton from '../../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';
import useSalesforceRecentlyViewedRecsQuery from '../../../../../helpers/Personalization/useSalesforceRecentlyViewedRecsQuery';
import useSalesforceBuyItAgainRecsQuery from '../../../../../helpers/Personalization/useSalesforceBuyItAgainRecsQuery';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import PersonalizedHPRecommendationsBuilder from './PersonalizedHPRecommendationsBuilder';
import RecentlyViewedRecsBuilder from '../../../EnterpriseID/RecentlyViewedProducts/RecentlyViewedRecsBuilder';
import BuyItAgainRecsBuilder from '../../../EnterpriseID/BuyItAgainProducts/BuyItAgainRecsBuilder';

const PersonalizedHomePageSFContainer = ({ blockObj, customerRecsFallbackTitle = 'Recently Viewed Products', recommendationsType = 'recentlyViewedRecs' }) => {
    const {
        products_to_show,
        pz_campaign_id: recentlyViewedCampaignFeature = '',
        pz_buy_again_campaign_id: buyAgainCampaignFeature = '',
    } = blockObj || {};

    const isRecentlyViewedRecs = recommendationsType === 'recentlyViewedRecs';
    const useSalesforceDynamicQuery = isRecentlyViewedRecs ? useSalesforceRecentlyViewedRecsQuery : useSalesforceBuyItAgainRecsQuery;

    const {
        loading,
        error,
        data,
        variables,
    } = useSalesforceDynamicQuery({
        queryName: isRecentlyViewedRecs ? 'RecentlyViewedRecommendations' : 'BuyItAgainRecommendations',
        campaignFeature: isRecentlyViewedRecs ? recentlyViewedCampaignFeature : buyAgainCampaignFeature,
        targeting: {
            pageType: 'home',
        },
        filterByZipCode: true,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    const dynamicSalesforceData = data?.[`${recommendationsType}`];

    useClickStreamCustomEventsTracking({
        salesforceResponse: dynamicSalesforceData,
        isFireImpression: dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return (
            <CarouselBuilderSkeleton
                nDisplayProducts={products_to_show}
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'PersonalizedHomePageSFContainer.js',
            message: 'Error loading data from Salesforce API',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    if (dynamicSalesforceData?.products || dynamicSalesforceData?.productRecs) {
        const cmsObj = dynamicSalesforceData?.productsContent || dynamicSalesforceData?.productRecsContent;
        const showNewProductCardStylig = cmsObj?.personalized_product_card_reference?.length > 0;
        if (!showNewProductCardStylig && isRecentlyViewedRecs) return <RecentlyViewedRecsBuilder data={data} blockObj={blockObj} />;
        if (!showNewProductCardStylig && !isRecentlyViewedRecs) return <BuyItAgainRecsBuilder data={data} blockObj={blockObj} />;
        return <PersonalizedHPRecommendationsBuilder data={data} products_to_show={products_to_show} customerRecsFallbackTitle={customerRecsFallbackTitle} recommendationsType={recommendationsType} />;
    }
    return null;
};

PersonalizedHomePageSFContainer.propTypes = {
    blockObj: shape({
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        padding: string,
    }).isRequired,
    customerRecsFallbackTitle: string.isRequired,
    recommendationsType: string.isRequired,
};

export default PersonalizedHomePageSFContainer;
