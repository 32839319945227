/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, shape } from 'prop-types';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';

import CarouselBuilderSkeleton from '../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';
import useSalesforceBuyItAgainRecsQuery from '../../../../helpers/Personalization/useSalesforceBuyItAgainRecsQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import BuyItAgainRecsBuilder from './BuyItAgainRecsBuilder';

const BuyItAgainRecsSFContainer = ({ blockObj }) => {
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);

    // show buy again recs only to registered users
    if (!isAuthenticated) return null;

    const {
        products_to_show,
        pz_buy_again_campaign_id: campaignFeature = '',
    } = blockObj || {};

    const {
        loading,
        error,
        data,
        variables,
    } = useSalesforceBuyItAgainRecsQuery({
        queryName: 'BuyItAgainRecommendations',
        campaignFeature,
        targeting: {
            pageType: 'home',
        },
        filterByZipCode: true,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.buyAgainRecs,
        isFireImpression: data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return (
            <CarouselBuilderSkeleton
                nDisplayProducts={products_to_show}
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'BuyItAgainRecsSFContainer.js',
            message: 'Error loading data from Salesforce API',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    if (data?.buyAgainRecs?.products || data?.buyAgainRecs?.productRecs) {
        return <BuyItAgainRecsBuilder data={data} blockObj={blockObj} />;
    }
    return null;
};

BuyItAgainRecsSFContainer.propTypes = {
    blockObj: shape({
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        padding: string,
    }).isRequired,
};

export default BuyItAgainRecsSFContainer;
