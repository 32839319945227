/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { useSelector } from 'react-redux';

import Heading from './Heading';
import MobileUIContainer from './MobileUIContainer';
import DesktopUIContainer from './DesktopUIContainer';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import MobileCarousel from './MobileCarousel';
import { getFeatureFlag } from '../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getEnterpriseId } from '../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getIsBot } from '../../../../../../state/ducks/App/App-Selectors';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportSubscriptionStatus } from '../../../../../../state/ducks/Common/Common-Selectors';
import validatePassport from '../../../../../helpers/validatePassport';
import { getPassportData } from '../../../../../../state/ducks/Passport/Passport-Selectors';
import DynamicPrice from '../../../GraphqlProductPage/Partials/GraphqlPDPSkus/DynamicPrice';

const useStyles = makeStyles((theme) => ({
    main: (styles) => ({
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        padding: '32px 0px',
        [theme.breakpoints.down(600)]: {
            padding: '24px 0px 32px',
            marginBottom: '32px',
        },
    }),
    root: {
        maxWidth: '1440px',
        margin: '0 auto',
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down(600)]: {
            padding: '0 16px',
        },
    },
    newDesign: {
        paddingRight: '0',
    },
    recentlyViewedSectionBGColor: (styles) => ({
        backgroundColor: styles?.backgroundColor,
    }),
    becauseYouViewedSectionBGColor: (styles) => ({
        backgroundColor: styles?.becauseYouViewedSectionBGColor,
    }),
}));

export default function RecentlyViewedGridUI({ sfData, cmsData, isNewDesign }) {
    const isMobile = useMediaQuery('(max-width: 600px)');

    const {
        minimum_rows_to_show,
        min_products_to_show,
        title_font_family: fontFamily,
        title_font_size: fontSize,
        title_font_style: fontStyle,
        title_font_color,
        background_color,
        because_you_viewed_grid_background_color,
        primary_grid_background_color,
        secondary_grid_background_color,
    } = cmsData || {};

    const recentlyViewedSectionBGColor = background_color?.color || primary_grid_background_color?.color || '#FFF8F0';
    const becauseYouViewedSectionBGColor = because_you_viewed_grid_background_color?.color || secondary_grid_background_color?.color || '#FFF8F0';
    const classes = useStyles({ backgroundColor: recentlyViewedSectionBGColor, becauseYouViewedSectionBGColor });

    const title = sfData?.recentlyViewedRecs?.campaign?.campaignResponses[0]?.payload?.productRecTitle;

    // Recently viewed title and subtitle
    const recentHeading = sfData?.recentlyViewedRecs?.productsContent?.personalized_carousel?.heading[0] || {};
    const recentSubHeading = sfData?.recentlyViewedRecs?.productsContent?.personalized_carousel?.heading[1] || {};
    const seedProduct = sfData?.recentlyViewedRecs?.seedProduct;
    const productName = seedProduct?.name;
    const customStyles = {
        fontFamily: recentHeading?.font_family,
        fontSize: recentHeading?.font_size,
        fontStyle: recentHeading?.font_style,
        color: recentHeading?.color?.color,
        fontFamily1: recentSubHeading?.font_family,
        fontSize1: recentSubHeading?.font_size,
        fontStyle1: recentSubHeading?.font_style,
        color1: recentSubHeading?.color?.color,
        backgroundColor: recentlyViewedSectionBGColor,
    };

    const showRecentlyViewed = sfData?.recentlyViewedRecs?.products?.length >= min_products_to_show;

    const enterpriseId = useSelector((state) => getEnterpriseId(state));
    const [isDynamicPricesFetched, setIsDynamicPricesFetched] = useState(false);
    const [dynamicPriceIsLoading, setDynamicPriceLoading] = useState(true);
    const [productSkuPrices, setProductSkuPrices] = useState([]);
    const isBot = useSelector((state) => getIsBot(state));
    const isOptimizeScriptEnabled = useSelector((state) => getFeatureFlag('is-optimize-script-enabled')(state));
    const isDynamicPriceEnabled = useSelector((state) => getFeatureFlag('is-dynamic-pricing-enabled')(state));

    // Set customer type ie. Passport, Registered.
    const isPassportUser = useSelector(getIsPassportUserLoggedIn);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    let customerTypeValue = [];
    if ((isPassportMember && isPassportUser) || isPassportAdded) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Passport',
        }];
    } else if (isAuthenticated) {
        customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];
    }

    const productRecs = sfData?.recentlyViewedRecs?.productRecs || [];
    const recentProducts = sfData?.recentlyViewedRecs?.products || [];

    const dynamicPricingProducts = [...recentProducts, ...productRecs].map((item) => ({ ...item, id: item.partNumber }));

    // Declaring Dynamic price call instance
    const dynamicPriceInstance = new DynamicPrice(dynamicPricingProducts, enterpriseId, false, '', false, customerTypeValue);

    useEffect(() => {
        if (isBot || !isDynamicPriceEnabled) {
            setProductSkuPrices([]);
            setDynamicPriceLoading(false);
            return;
        }
        if ((isOptimizeScriptEnabled && isDynamicPriceEnabled) || isDynamicPricesFetched) {
            return;
        }

        const useZipcode = false;

        setDynamicPriceLoading(true);
        dynamicPriceInstance.call(useZipcode, '').then((productPrices) => {
            setProductSkuPrices(productPrices?.newPrices || []);
            setDynamicPriceLoading(false);
            setIsDynamicPricesFetched(true);
        }).catch(() => {
            setDynamicPriceLoading(false);
        });
    }, [
        isDynamicPriceEnabled,
        isOptimizeScriptEnabled,
        isDynamicPricesFetched,
    ]);

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: sfData?.recentlyViewedRecs,
        isFireImpression: false, // don't fire impression from here.
        page: { type: 'home' },
    });

    if (isMobile) {
        return (
            <>
                {
                    showRecentlyViewed && (
                        <div className={`${classes.main} ${classes.recentlyViewedSectionBGColor}`}>
                            <div className={classes.root}>
                                <Heading
                                    title={recentHeading?.copy || 'Recently Viewed Products'}
                                    subTitle={recentSubHeading?.copy || 'These gifts miss you. Take another look!'}
                                    styles={customStyles}
                                />
                            </div>
                            <div className={`${classes.root} ${isNewDesign ? classes.newDesign : ''}`}>
                                {isNewDesign
                                    ? (
                                        <MobileCarousel
                                            products={sfData?.recentlyViewedRecs?.products}
                                            handleLinkOnClick={trackClickThrough}
                                            dynamicPriceInstance={dynamicPriceInstance}
                                            productSkuPrices={productSkuPrices}
                                            dynamicPriceIsLoading={dynamicPriceIsLoading}
                                        />
                                    )
                                    : (
                                        <MobileUIContainer
                                            products={sfData?.recentlyViewedRecs?.products}
                                            minRowsToShow={minimum_rows_to_show || 1}
                                            trackClickThrough={trackClickThrough}
                                            containerBGColor={recentlyViewedSectionBGColor}
                                            dynamicPriceInstance={dynamicPriceInstance}
                                            productSkuPrices={productSkuPrices}
                                            dynamicPriceIsLoading={dynamicPriceIsLoading}
                                        />
                                    )}
                            </div>
                        </div>
                    )
                }
                {sfData?.recentlyViewedRecs?.productRecs?.length > 0 && (
                    <div className={`${classes.main} ${classes.becauseYouViewedSectionBGColor}`}>
                        <div className={classes.root}>
                            <Heading
                                title={title || 'Because you viewed...'}
                                subTitle={productName}
                                styles={{
                                    fontFamily, fontSize, fontStyle, color: title_font_color?.color,
                                }}
                                product={seedProduct}
                                handleLinkOnClick={trackClickThrough}
                            />
                        </div>
                        <div className={`${classes.root} ${isNewDesign ? classes.newDesign : ''}`}>
                            {isNewDesign
                                ? (
                                    <MobileCarousel
                                        products={sfData?.recentlyViewedRecs?.productRecs}
                                        handleLinkOnClick={trackClickThrough}
                                        dynamicPriceInstance={dynamicPriceInstance}
                                        productSkuPrices={productSkuPrices}
                                        dynamicPriceIsLoading={dynamicPriceIsLoading}
                                    />
                                )
                                : (
                                    <MobileUIContainer
                                        products={sfData?.recentlyViewedRecs?.productRecs}
                                        minRowsToShow={minimum_rows_to_show || 1}
                                        trackClickThrough={trackClickThrough}
                                        containerBGColor={becauseYouViewedSectionBGColor}
                                        dynamicPriceInstance={dynamicPriceInstance}
                                        productSkuPrices={productSkuPrices}
                                        dynamicPriceIsLoading={dynamicPriceIsLoading}
                                    />
                                )}
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (sfData?.recentlyViewedRecs?.productRecs?.length > 0 || showRecentlyViewed) {
        return (
            <div className={`${classes.main}`}>
                <div className={classes.root}>
                    {showRecentlyViewed && (
                        <Heading
                            title={recentHeading?.copy || 'Recently Viewed Products'}
                            subTitle={recentSubHeading?.copy || 'These gifts miss you. Take another look!'}
                            styles={customStyles}
                        />
                    )}
                    {sfData?.recentlyViewedRecs?.productRecs?.length > 0 && (
                        <Heading
                            title={title || 'Because you viewed...'}
                            subTitle={productName}
                            styles={{
                                fontFamily, fontSize, fontStyle, color: title_font_color?.color, backgroundColor: becauseYouViewedSectionBGColor,
                            }}
                            product={seedProduct}
                            handleLinkOnClick={trackClickThrough}
                        />
                    )}
                </div>
                <div className={classes.root}>
                    <DesktopUIContainer
                        sfData={sfData}
                        minRowsToShow={minimum_rows_to_show || 1}
                        showRecentlyViewed={showRecentlyViewed}
                        trackClickThrough={trackClickThrough}
                        recentlyViewedSectionBGColor={recentlyViewedSectionBGColor}
                        becauseYouViewedSectionBGColor={becauseYouViewedSectionBGColor}
                        dynamicPriceInstance={dynamicPriceInstance}
                        productSkuPrices={productSkuPrices}
                        dynamicPriceIsLoading={dynamicPriceIsLoading}
                    />
                </div>
            </div>
        );
    }

    return null;
}

RecentlyViewedGridUI.propTypes = {
    sfData: object,
    cmsData: object,
    isNewDesign: bool,
};

RecentlyViewedGridUI.defaultProps = {
    sfData: {},
    cmsData: {},
    isNewDesign: false,
};
