/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable react/jsx-curly-spacing */

import React, { useEffect } from 'react';
import {
    bool, func, object, string,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import { useLocation, useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import messageCss from '../../../UniversalTemplate/SubComponents/Message/Message.module.css';
import getBackgroundColor from '../../../../../helpers/getBackgroundColor';
import replaceSpecialChar from '../../../../../helpers/replaceSpecialChar';
import normalizeAltCopy from '../../../../../helpers/normalizeAltCopy';
import OfferGatewayButton from './OfferGatewayButton';

// helpers
import cmsCssClassesToString from '../../../../EnterpriseDesignSystem/helpers/cmsCssClassesToString';
import { setOfferGatewayData } from '../../../../../../state/ducks/App/App-Actions';
import { emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import noop from '../../../../../helpers/noop';
import useOfferGatewayClickHandler from './useOfferGatewayClickHandler';

const useCustomStyles = makeStyles((theme) => ({
    banner: ({ bannerHeight, bannerWidth }) => ({
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: bannerHeight,
        padding: '47px',
        flexDirection: 'column',
        textDecoration: 'none',
        textAlign: 'left',
    }),
}));

const parsePosition = (position) => {
    useStyles(messageCss);
    const positionStyles = {};
    const styleArr = position?.split(';') || [];
    styleArr?.forEach((style) => {
        const [propName, propValue] = style?.split(':') || [];
        if (propName && propValue) {
            positionStyles[propName.trim()] = propValue.trim().replace(/'/g, '');
        }
    });
    return positionStyles;
};

const OfferGatewayMessageStandard = ({
    data, fullBackground, size, salesforceResponse, bannerHeight, bannerWidth,
}) => {
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'OfferGatewayMessageStandard.js',
            message: 'No data available to render component',
        });
    }

    const classes = useCustomStyles({ bannerHeight, bannerWidth });
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    let messagePosition = {};
    switch (size) {
        case 'tall':
            messagePosition = data?.message_position_tall;
            break;
        case 'medium':
            messagePosition = data?.message_position_medium;
            break;
        default: messagePosition = data?.message_position;
    }
    const {
        has_background = false,
        message_background_color = {},
        content = {
            heading: { css_classes: ['header2', 'u-margin-bottom-10'], text: '' },
            sub_heading: { css_classes: ['text1', 'u-margin-bottom-20'], text: '' },
        },
        heading = '', sub_heading = '',
        message_theme = 'onLight',
        message_border_color = '',
        message_has_border = false,
        color = '',
        heading_font = '',
        heading_font_size = '',
        sub_heading_font = '',
        sub_heading_font_size = '',
        image = '',
        max_image_width = '',
        custom_button = [],
    } = data;
    if (!image?.url && !heading && !sub_heading && data?.cta?.length === 0) {
        return null;
    }

    const campaignPayload = salesforceResponse.campaign?.campaignResponses?.[0]?.payload || {};

    const lockButtons = custom_button.filter((item) => item?.offer_status === 'lock');
    const unlockButtons = custom_button.filter((item) => item?.offer_status === 'unlock' || item?.offer_status === 'copy');
    const isOfferUnlocked = campaignPayload.offerFlow === 'Offer Unlocked';

    const { handleButtonClick, isPromoCopied } = useOfferGatewayClickHandler(salesforceResponse);

    const addQueryParameter = () => {
        if (campaignPayload?.offerBannerCode) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('r', campaignPayload.offerBannerCode);
            history.push({
                pathname: location.pathname,
                search: searchParams.toString(),
            });
        }
    };

    useEffect(() => {
        if (isOfferUnlocked) {
            dispatch(emitCustomTrackingEvent({
                action: 'Impression',
                eventSubType: 'feature-tracking',
                category: 'Offer Gateway',
                label: 'Offer Unlocked',
                page: { type: 'home' },
                salesforceResponse,
            }));
            dispatch(setOfferGatewayData({
                offerUnlocked: true,
            }));
            if (!campaignPayload.collectionURL) {
                addQueryParameter();
            }
        }
    }, [isOfferUnlocked]);

    const position = parsePosition(messagePosition?.message_position);
    const headingText = content?.heading?.text;
    const headingCssClasses = cmsCssClassesToString(content?.heading?.css_classes);
    const subHeaderText = content?.sub_heading?.text;
    const subHeaderCssClasses = cmsCssClassesToString(content?.sub_heading?.css_classes);
    const messageBackground = fullBackground && data?.has_background;
    const msgBorderColor = message_has_border ? `1px solid ${getBackgroundColor(message_border_color)}` : '';

    if (isOfferUnlocked && (campaignPayload?.offerEndState === 'Collection Page' && unlockButtons?.[0]?.cta_button?.link?.href && campaignPayload?.collectionURL)) {
        if (campaignPayload?.offerBannerCode) {
            unlockButtons[0].cta_button.link.href = `${campaignPayload.collectionURL}?r=${campaignPayload.offerBannerCode}`;
        } else {
            unlockButtons[0].cta_button.link.href = campaignPayload.collectionURL;
        }
    }

    if (isPromoCopied) {
        unlockButtons.forEach((item) => {
            if (item?.offer_status === 'copy') {
                if (item?.icon?.type) {
                    // eslint-disable-next-line no-param-reassign
                    item.icon.type = 'check';
                }
            }
        });
    }

    // start bloat
    // These four should be stripped when we get a proper font selector or RTE in here
    const standardHeadingFontSet = '"PlayfairDisplayBold", "Crimson", "ArcherBold", Garamond, Times, serif';
    const standardSubHeadingFontSet = '"LatoRegular", "Lato", "NeutraBold", Verdana, Geneva, Tahoma, sans-serif;';
    const cleanFont = (font) => {
        const regex = /font-family:(.+);/gm;
        return regex.exec(`${font}`)?.[1];
    };
    // eslint-disable-next-line no-nested-ternary
    const whichFont = (font, type) => (font.length ? cleanFont(font) : (type === 'heading' ? standardHeadingFontSet : standardSubHeadingFontSet));
    const headingStyleGenerator = (headingClass, headingFont, headingFontSize, type) => {
        const style = {};
        style.fontFamily = whichFont(headingFont, type);
        style[headingClass] = color;
        if (headingFontSize) style.fontSize = headingFontSize;
        return { style };
    };
    // end bloat

    let containerClass = '';
    switch (messagePosition?.message_position) {
        case 'align-items: center; justify-content: center;':
        case 'align-items: center; justify-content:  flex-end;':
        case 'align-items: center; justify-content: flex-start;':
            containerClass = `${classes.banner} ${messageCss.messageTextCenter}`;
            break;
        case 'align-items: flex-end; justify-content: center;':
        case 'align-items: flex-end; justify-content: flex-end;':
        case 'align-items: flex-end; justify-content: flex-start;':
            containerClass = `${classes.banner} ${messageCss.messageTextRight}`;
            break;
        default: containerClass = classes.banner;
    }

    return (
        <div className={`${containerClass} ${messageBackground ? messageCss.backgroundContainer : ''}`} style={{ ...position }}>
            <div className={`${messageCss[message_theme || 'onLight']} ${messageCss.messageWrapper} ${has_background ? messageCss.messageBgColor : ''}`} style={{ '--msg-bg-color': getBackgroundColor(message_background_color, true), border: msgBorderColor }}>
                {image?.url && <img src={image.url} alt={normalizeAltCopy(image.title)} style={{ marginBottom: '1em', maxWidth: (max_image_width ? `${max_image_width}px` : '80%') }} />}
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {headingText ? <h3 className={headingCssClasses}>{replaceSpecialChar(headingText, true)}</h3> : (heading && <h3 className={messageCss.heading} {...headingStyleGenerator('--message-heading-color', heading_font, heading_font_size, 'heading')}>{replaceSpecialChar(heading, true)}</h3>)}
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                {subHeaderText ? <p className={subHeaderCssClasses}> {replaceSpecialChar(subHeaderText, true)}</p> : sub_heading && <div className={messageCss.subHeading} {...headingStyleGenerator('--message-subheading-color', sub_heading_font, sub_heading_font_size, 'subheading')}> {replaceSpecialChar(sub_heading, true)}</div>}
                { /* TO BE DELETED WHEN EDS BUTTON GETS APPROVED */}

                <OfferGatewayButton
                    ctaArray={isOfferUnlocked ? unlockButtons : lockButtons}
                    buttonContainer={messageCss.buttonContainer}
                    handleClick={handleButtonClick}
                />
            </div>
        </div>
    );
};

OfferGatewayMessageStandard.propTypes = {
    data: object.isRequired,
    fullBackground: bool,
    size: string,
    salesforceResponse: object,
    isPromoCopied: bool,
    handleClick: func,
    bannerHeight: string,
    bannerWidth: string,
};
OfferGatewayMessageStandard.defaultProps = {
    fullBackground: false,
    size: 'banner',
    salesforceResponse: {},
    isPromoCopied: false,
    handleClick: noop,
    bannerHeight: '',
    bannerWidth: '',
};

export default OfferGatewayMessageStandard;
