/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import { bool, object, string } from 'prop-types';
import { useSelector } from 'react-redux';

import Heading from './Partials/Heading';
import MobileUIContainer from './Partials/MobileGridUIContainer';
import DesktopUIContainer from './Partials/GridUIContainer';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';

const useStyles = makeStyles((theme) => ({
    main: (styles) => ({
        width: '100%',
        maxWidth: '1400px',
        margin: '0 auto',
        padding: '32px 0px',
        [theme.breakpoints.down(600)]: {
            padding: '24px 0px 32px',
            marginBottom: '32px',
        },
    }),
    root: {
        maxWidth: '1440px',
        margin: '0 auto',
        display: 'flex',
        gap: '16px',
        [theme.breakpoints.down(600)]: {
            padding: '0 16px',
        },
    },
    newDesign: {
        paddingRight: '0',
    },
    recentlyViewedSectionBGColor: (styles) => ({
        backgroundColor: styles?.backgroundColor,
    }),
    becauseYouViewedSectionBGColor: (styles) => ({
        backgroundColor: styles?.becauseYouViewedSectionBGColor,
    }),
}));

export default function PersonalizedHomePageGridUI({
    sfData, cmsData, isNewDesign, productStyleConfig, productRecStyleConfig, recommendationsType,
}) {
    const isMobile = useMediaQuery('(max-width: 600px)');

    const {
        min_products_to_show,
        background_color,
        because_you_viewed_grid_background_color,
    } = cmsData || {};

    const recentlyViewedSectionBGColor = '#FFF';
    const becauseYouViewedSectionBGColor = '#FFF';
    const classes = useStyles({ backgroundColor: recentlyViewedSectionBGColor, becauseYouViewedSectionBGColor });

    const dynamicSalesforceData = sfData?.[`${recommendationsType}`];
    const title = dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.productRecTitle || dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.buyAgainRecTitle;
    const subTitle = dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.productRecSubTitle || dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.buyAgainRecSubTitle;

    // Recently viewed title and subtitle
    const recentHeading = productStyleConfig?.main_title || '';
    const recentSubHeading = productStyleConfig?.subtitle || '';
    const showRecentSubHeading = productStyleConfig?.show_subtitle;
    const showSubTitle = productRecStyleConfig?.show_subtitle;

    const productTitleStyling = productStyleConfig?.title_styling || {};
    const productSubtitleStyling = productStyleConfig?.subtitle_styling || {};
    const productsCustomStyles = {
        fontFamily: productTitleStyling?.title_font_family,
        fontSize: productTitleStyling?.title_font_size,
        fontStyle: productTitleStyling?.title_font_style,
        color: productTitleStyling?.title_font_color?.color,
        fontFamily1: productSubtitleStyling?.font_family,
        fontSize1: productSubtitleStyling?.font_size,
        fontStyle1: productSubtitleStyling?.font_style,
        color1: productSubtitleStyling?.font_color?.color,
        backgroundColor: '#FFF' || recentlyViewedSectionBGColor,
        textAlign: productTitleStyling?.align_title,
    };

    const productRecTitleStyling = productRecStyleConfig?.title_styling || {};
    const productRecSubtitleStyling = productRecStyleConfig?.subtitle_styling || {};
    const productRecsCustomStyles = {
        fontFamily: productRecTitleStyling?.title_font_family,
        fontSize: productRecTitleStyling?.title_font_size,
        fontStyle: productRecTitleStyling?.title_font_style,
        color: productRecTitleStyling?.title_font_color?.color,
        fontFamily1: productRecSubtitleStyling?.font_family,
        fontSize1: productRecSubtitleStyling?.font_size,
        fontStyle1: productRecSubtitleStyling?.font_style,
        color1: productRecSubtitleStyling?.font_color?.color,
        backgroundColor: '#FFF' || recentlyViewedSectionBGColor,
        textAlign: productRecTitleStyling?.align_title,
    };

    const showRecentlyViewed = dynamicSalesforceData?.products?.length >= min_products_to_show;

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: dynamicSalesforceData,
        isFireImpression: false, // don't fire impression from here.
        page: { type: 'home' },
    });

    if (isMobile) {
        return (
            <>
                {
                    showRecentlyViewed && (
                        <div className={`${classes.main} ${classes.recentlyViewedSectionBGColor}`}>
                            {!productStyleConfig?.show_nested_title && (
                                <div className={classes.root}>
                                    <Heading
                                        title={recentHeading || 'Recently Viewed Products'}
                                        subTitle={recentSubHeading || 'These gifts miss you. Take another look!'}
                                        styles={productsCustomStyles}
                                        showSubTitle={showRecentSubHeading}
                                    />
                                </div>
                            )}
                            <div className={`${classes.root} ${isNewDesign ? classes.newDesign : ''}`}>
                                <MobileUIContainer
                                    products={dynamicSalesforceData?.products}
                                    minRowsToShow={productStyleConfig?.number_of_rows_to_display || 1}
                                    trackClickThrough={trackClickThrough}
                                    containerBGColor="#FFF"
                                    productStyleConfig={productStyleConfig}
                                />
                            </div>
                        </div>
                    )
                }
                {dynamicSalesforceData?.productRecs?.length > 0 && (
                    <div className={`${classes.main} ${classes.becauseYouViewedSectionBGColor}`}>
                        {!productRecStyleConfig?.show_nested_title && (
                            <div className={classes.root}>
                                <Heading
                                    title={title || 'Because you viewed...'}
                                    subTitle={subTitle}
                                    styles={productRecsCustomStyles}
                                    product={null}
                                    handleLinkOnClick={trackClickThrough}
                                    showSubTitle={showSubTitle}
                                />
                            </div>
                        )}
                        <div className={`${classes.root} ${isNewDesign ? classes.newDesign : ''}`}>
                            <MobileUIContainer
                                products={dynamicSalesforceData?.productRecs}
                                minRowsToShow={productRecStyleConfig?.number_of_rows_to_display || 1}
                                trackClickThrough={trackClickThrough}
                                containerBGColor="#FFF"
                                productStyleConfig={productRecStyleConfig}
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }

    if (dynamicSalesforceData?.productRecs?.length > 0 || showRecentlyViewed) {
        return (
            <>
                <div className={`${classes.main}`}>
                    <div className={classes.root}>
                        {!productStyleConfig?.show_nested_title && showRecentlyViewed && (
                            <Heading
                                title={recentHeading || 'Recently Viewed Products'}
                                subTitle={recentSubHeading || 'These gifts miss you. Take another look!'}
                                styles={productsCustomStyles}
                                showSubTitle={showRecentSubHeading}
                            />
                        )}
                    </div>
                    <div className={classes.root}>
                        <DesktopUIContainer
                            products={dynamicSalesforceData?.products}
                            minRowsToShow={productStyleConfig?.number_of_rows_to_display || 1}
                            showRecentlyViewed={showRecentlyViewed}
                            productClickCallback={trackClickThrough}
                            recentlyViewedSectionBGColor="#FFF"
                            becauseYouViewedSectionBGColor="#FFF"
                            productStyleConfig={productStyleConfig}
                        />
                    </div>
                </div>
                <div className={`${classes.main}`}>
                    <div className={classes.root}>
                        {!productRecStyleConfig?.show_nested_title && dynamicSalesforceData?.productRecs?.length > 0 && (
                            <Heading
                                title={title || 'Because you viewed...'}
                                subTitle={subTitle}
                                styles={productRecsCustomStyles}
                                showSubTitle={showSubTitle}
                            />
                        )}
                    </div>
                    <div className={classes.root}>
                        <DesktopUIContainer
                            products={dynamicSalesforceData?.productRecs}
                            minRowsToShow={productRecStyleConfig?.number_of_rows_to_display || 1}
                            showRecentlyViewed={false}
                            productClickCallback={trackClickThrough}
                            recentlyViewedSectionBGColor="#FFF"
                            becauseYouViewedSectionBGColor="#FFF"
                            productStyleConfig={productRecStyleConfig}
                        />
                    </div>
                </div>
            </>
        );
    }

    return null;
}

PersonalizedHomePageGridUI.propTypes = {
    sfData: object,
    cmsData: object,
    isNewDesign: bool,
    productStyleConfig: object,
    productRecStyleConfig: object,
    recommendationsType: string.isRequired,
};

PersonalizedHomePageGridUI.defaultProps = {
    sfData: {},
    cmsData: {},
    isNewDesign: false,
    productStyleConfig: {},
    productRecStyleConfig: {},
};
