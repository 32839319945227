/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    object, string, func,
} from 'prop-types';
import { useDispatch } from 'react-redux';

import ComponentErrorBoundary from '../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import PersonalizedHPCarouselBuilder from '../../GraphqlHomePage/Partials/PersonalizedHomePageCarouselBuilder/PersonalizedHomePageCarouselBuilder';
import noop from '../../../../helpers/noop';
import { emitCustomTrackingEvent } from '../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import PersonalizedHPGridUI from '../../GraphqlHomePage/Partials/PersonalizedHomePageGridUI/PersonalizedHomePageGridUI';

const TrendingProductRecsBuilder = ({
    data, products_to_show, cmsTitle, recommendationsType = 'productRecommendationsSFMulti', setShowSkeleton, productClickCallback,
}) => {
    const dispatch = useDispatch();
    const dynamicSalesforceData = data?.[`${recommendationsType}`]?.[0];

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    const renderCarousel = (carouselTitle, products, productStyleConfig) => (
        <ComponentErrorBoundary function="PersonalizedHomePageSFContainer" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
            <PersonalizedHPCarouselBuilder
                blockData={{
                    productsToShow: products_to_show || {},
                    title: carouselTitle,
                    titleMargin: productStyleConfig?.title_styling?.title_margin,
                    titleFontColor: productStyleConfig?.title_styling?.title_font_color?.color,
                    titleFontSize: productStyleConfig?.title_styling?.title_font_size,
                    titleFontStyle: productStyleConfig?.title_styling?.title_font_style,
                    titleFontFamily: productStyleConfig?.title_styling?.title_font_family,
                    alignTitle: productStyleConfig?.title_styling?.align_title,
                    padding: productStyleConfig?.title_styling?.padding,
                }}
                productClickCallback={productClickCallback}
                products={products}
                demandType="XS"
                productStyleConfig={productStyleConfig}
            />
        </ComponentErrorBoundary>
    );

    if (dynamicSalesforceData?.products && dynamicSalesforceData?.products.length > 0) {
        const carouselData = dynamicSalesforceData?.content?.personalized_carousel;
        const titleSF = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle || dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.fallbackProductRecTitle || carouselData?.heading[0]?.copy || cmsTitle || 'Trending Products For You';
        const subTitle = dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.productRecSubTitle || '';
        const isGridView = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.layoutDisplayType === 'GRID';
        const minimumProductsToShow = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.minimumProductsToShow || 2;
        const products = dynamicSalesforceData?.products;
        const productStylingConfig = dynamicSalesforceData?.content?.personalized_product_card_reference?.length > 0 ? dynamicSalesforceData?.content?.personalized_product_card_reference?.[0] : (dynamicSalesforceData?.content || {});

        const trackImpression = () => dispatch(emitCustomTrackingEvent({
            action: 'Impression',
            salesforceResponse: dynamicSalesforceData,
            page: { type: 'home' },
            eventSubType: 'campaign-tracking',
            displayType: '',
            minimumProductsToShow,
        }));

        trackImpression();

        if (isGridView) {
            return (
                <ComponentErrorBoundary function="PersonalizedHPGridUI" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
                    <PersonalizedHPGridUI
                        sfData={{ productRecommendationsSFMulti: dynamicSalesforceData }}
                        cmsData={{
                            title: titleSF, subTitle, min_products_to_show: minimumProductsToShow,
                        }}
                        isNewDesign={false}
                        productClickCallback={productClickCallback}
                        productStyleConfig={productStylingConfig}
                        productRecStyleConfig={null}
                        recommendationsType={recommendationsType}
                    />
                </ComponentErrorBoundary>
            );
        }
        return renderCarousel(titleSF, products, productStylingConfig);
    }
    return null;
};

TrendingProductRecsBuilder.propTypes = {
    data: object.isRequired,
    products_to_show: object,
    cmsTitle: string,
    recommendationsType: string.isRequired,
    setShowSkeleton: func,
    productClickCallback: func,
};

TrendingProductRecsBuilder.defaultProps = {
    products_to_show: {},
    cmsTitle: null,
    setShowSkeleton: noop,
    productClickCallback: noop,
};

export default TrendingProductRecsBuilder;
