/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { string, bool } from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactHTMLParser from 'react-html-parser';
import { getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { transformCharacters } from '../../../../../../helpers/markdown/characterTransformer';
import { getActiveABTests } from '../../../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const useStyles = makeStyles((theme) => ({
    productName: {
        fontWeight: '400',
        fontFamily: theme.typography.fontFamily,
        fontSize: '0.875rem',
        lineHeight: '1.188rem !important',
        letterSpacing: '0.01em',
        textAlign: 'left',
    },
    productListingName: {
        fontWeight: 'bold',
        fontSize: '1em',
        letterSpacing: '.4px',
        fontFamily: theme?.typography?.fontFamily,
        color: theme.palette.product?.name || theme.palette.colorPrimary,
        webkitFontSmoothing: 'antialiased',
    },
    productNameFoodAbTest: {
        fontFamily: 'LatoBold',
        fontSize: '18px',
        lineHeight: '26px',
        letterSpacing: '0.01em',
        color: theme.palette.product?.name || theme.palette.colorPrimary,
    },
    productNameFloralAbTest: {
        fontFamily: 'LatoMedium',
        fontSize: theme.palette?.text?.fontSize15 || '15px',
        lineHeight: '23px',
        // letterSpacing: '0.02em',
        color: '#000',
    },
    productNameLength: {
        margin: 0,
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        whiteSpace: 'normal',
    },
}));

const ProductName = ({ name, showSimpleProductRedesignAbTest }) => {
    const classes = useStyles();
    const presentationFamily = useSelector(getPresentationFamily);
    const activeABTests = useSelector(getActiveABTests);
    const isFood = presentationFamily === 'food';
    const productNameStyle = isFood ? classes.productListingName : classes.productName;
    const isVariantEnabled = isFood ? classes.productNameFoodAbTest : classes.productNameFloralAbTest;

    /*  ab test
        if no longer needed remove
    */
    if (activeABTests?.mobile_simple_product_redesign && showSimpleProductRedesignAbTest) {
        return (
            <p className={`${classes.productNameLength} ${isVariantEnabled}`}>
                <span className={`${classes.productNameLength} ${isVariantEnabled}`}>
                    {transformCharacters(ReactHTMLParser(name))}
                </span>
            </p>
        );
    }
    /*  END ab test
        if no longer needed remove
    */
    return (
        <Typography className={productNameStyle}>
            <span className={`${productNameStyle} transformedName`}>
                {transformCharacters(ReactHTMLParser(name))}
            </span>
        </Typography>
    );
};

ProductName.propTypes = {
    name: string.isRequired,
    showSimpleProductRedesignAbTest: bool.isRequired,
};

export default ProductName;
