/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { string, shape } from 'prop-types';
import mbpLogger from 'mbp-logger';

import CarouselBuilderSkeleton from '../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';
import useSalesforceRecentlyViewedRecsQuery from '../../../../helpers/Personalization/useSalesforceRecentlyViewedRecsQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import RecentlyViewedRecsBuilder from './RecentlyViewedRecsBuilder';

const RecentlyViewedRecsSFContainer = ({ blockObj }) => {
    const {
        products_to_show,
        pz_campaign_id: campaignFeature = '',
    } = blockObj || {};

    const {
        loading,
        error,
        data,
        variables,
    } = useSalesforceRecentlyViewedRecsQuery({
        queryName: 'RecentlyViewedRecommendations',
        campaignFeature,
        targeting: {
            pageType: 'home',
        },
        filterByZipCode: true,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    useClickStreamCustomEventsTracking({
        salesforceResponse: data?.recentlyViewedRecs,
        isFireImpression: data?.recentlyViewedRecs?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    if (loading || variables?.skip) {
        return (
            <CarouselBuilderSkeleton
                nDisplayProducts={products_to_show}
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        );
    }

    if (error) {
        mbpLogger.logError({
            function: 'RecentlyViewedRecsSFContainer.js',
            message: 'Error loading data from Salesforce API',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return null;
    }

    if (data?.recentlyViewedRecs?.products || data?.recentlyViewedRecs?.productRecs) {
        return <RecentlyViewedRecsBuilder data={data} blockObj={blockObj} />;
    }
    return null;
};

RecentlyViewedRecsSFContainer.propTypes = {
    blockObj: shape({
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        padding: string,
    }).isRequired,
};

export default RecentlyViewedRecsSFContainer;
