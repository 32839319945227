/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import {
    shape, string, bool, array,
} from 'prop-types';
// eslint-disable-next-line import/no-cycle
import GenericSkeleton from '../../GraphqlSkeletonComponents/GenericSkeleton';
import useSalesforcePersonalizedContentQuery from '../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useSalesforceRecentlyViewedRecsQuery from '../../../../helpers/Personalization/useSalesforceRecentlyViewedRecsQuery';
import useSalesforceBuyItAgainRecsQuery from '../../../../helpers/Personalization/useSalesforceBuyItAgainRecsQuery';
// eslint-disable-next-line import/no-cycle
import HomePageBlock from '../../GraphqlHomePage/HomePageBlock';
import FallbackContentContainer from '../Common/FallbackContentContainer';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import { getIsAuthenticatedStatus } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import UniversalHomepageComponent from '../../GraphqlHomePage/Partials/UniversalHomepageComponent/UniversalHomepageComponent';
import CarouselBuilderSkeleton from '../../GraphqlSkeletonComponents/CarouselBuilderSkeleton';

const PersonalizedContentBlock = ({
    data: {
        content_type: contentType = '',
        content_types,
        universal_blocktype,
        pz_campaign_id: interactionName = '',
        content_zones,
        skeleton_height,
        skeleton_width,
        skeleton_margin = '0 auto',
        fallback_uid: fallbackUID = '',
        show_module: showModule = '',
    },
    ssrDeviceType,
    presentationFamily,
    brand,
    hasTopMargin,
}) => {
    const [showSkeleton, setShowSkeleton] = useState(true);
    // setShowSkeleton is used to show skeleton, until our components in homepageblock loads.
    const isBot = useSelector(getIsBot);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);

    if (isBot) {
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
                hasTopMargin={hasTopMargin}
            />
        );
    }

    if (typeof window === 'undefined') {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} id="PersonalizedContentBlock" />;
    }
    const targeting = {
        pageType: 'home',
    };
    if (content_zones?.length && content_zones?.[0]) {
        targeting.contentZones = content_zones;
    }

    const callPersonalizedRecentlyViewedQuery = showModule && showModule.includes('Recently Viewed');
    const callPersonalizedBuyAgainQuery = showModule && showModule.includes('Buy Again');

    // show buy again recs only to registered users
    // load recently viewed/buy again module only as per cms selection.
    if (contentType && contentType?.includes('product_recommendations_service_v2') && ((!callPersonalizedRecentlyViewedQuery && !callPersonalizedBuyAgainQuery) || (!isAuthenticated && callPersonalizedBuyAgainQuery))) {
        return null;
    }

    let dynamicSalesforceQueryParams = {
        queryName: 'PersonalizedContent',
        interactionName,
        targeting,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    };

    let useSalesforceDynamicQuery = useSalesforcePersonalizedContentQuery;

    if (callPersonalizedRecentlyViewedQuery || callPersonalizedBuyAgainQuery) {
        dynamicSalesforceQueryParams = {
            queryName: callPersonalizedRecentlyViewedQuery ? 'RecentlyViewedRecommendations' : 'BuyItAgainRecommendations',
            campaignFeature: interactionName?.split('-')?.[2].trim(),
            targeting,
            filterByZipCode: true,
            optInHoldoutParticipation: true,
            resolveIdentity: true,
        };
    }

    if (callPersonalizedRecentlyViewedQuery || callPersonalizedBuyAgainQuery) useSalesforceDynamicQuery = callPersonalizedRecentlyViewedQuery ? useSalesforceRecentlyViewedRecsQuery : useSalesforceBuyItAgainRecsQuery;

    const {
        loading, error, data, variables,
    } = useSalesforceDynamicQuery(dynamicSalesforceQueryParams);

    if (loading || variables?.skip) {
        return callPersonalizedRecentlyViewedQuery || callPersonalizedBuyAgainQuery ? (
            <CarouselBuilderSkeleton
                breakPoints={{ mobile: 601, desktop: 769 }}
            />
        ) : <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} />;
    }

    if (error) {
        mbpLogger.logError({
            function: `PersonalizedContentBlock - content_types = ${content_types.toString()} interactionName = ${interactionName}`,
            message: 'Error loading API data',
            appName: process.env.npm_package_name,
            module: 'PersonalizedContent',
            jsError: error,
        });
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
                hasTopMargin={hasTopMargin}
            />
        );
    }

    const showRecentlyViewedModule = data?.recentlyViewedRecs?.productRecs?.length > 0 || data?.recentlyViewedRecs?.products?.length > 0;
    const showBuyAgainModule = data?.buyAgainRecs?.productRecs?.length > 0 || data?.buyAgainRecs?.products?.length > 0;

    if (data && (data?.contentSF?.content || showRecentlyViewedModule || showBuyAgainModule)) {
        const DynamicProductCardSFResponseData = { campaign: data?.[`${showRecentlyViewedModule ? 'recentlyViewedRecs' : 'buyAgainRecs'}`]?.campaign, content: { ...data?.[`${showRecentlyViewedModule ? 'recentlyViewedRecs' : 'buyAgainRecs'}`], show_module: showModule } };
        const { campaign, content } = data?.contentSF || DynamicProductCardSFResponseData;
        const sfContentType = campaign?.campaignResponses?.[0]?.payload?.cmsContentType || campaign?.campaignResponses?.[0]?.payload?.promotions?.[0]?.attributes?.cmsContentType?.value;
        const contentTypeValueObject = {};
        const contentTypesToIncludeRefs = ['generic_banner'];
        if (contentType === 'universal_components') {
            const wrappedContent = {
                [universal_blocktype]: [
                    {
                        ...content,
                        _content_type_uid: sfContentType,
                    },
                ],
            };

            return (
                <UniversalHomepageComponent data={wrappedContent} salesforceResponse={data?.contentSF} />
            );
        }
        if (!sfContentType || !content_types.includes(sfContentType)) {
            mbpLogger.logError({
                function: `PersonalizedContentBlock - salesforce content_type = ${sfContentType} is not matching with CMS content_types entries = ${content_types.toString()}`,
                message: 'Salesforce content_type not matching with CMS content_types entries',
                appName: process.env.npm_package_name,
                module: 'PersonalizedContent',
            });
            return null;
        }

        if (sfContentType === 'gift_finder') contentTypeValueObject['gift_finder_reference'] = [content];
        if (contentTypesToIncludeRefs.includes(sfContentType)) contentTypeValueObject['reference'] = [content];

        const block = {
            [sfContentType]: sfContentType === 'gift_finder' || contentTypesToIncludeRefs.includes(sfContentType) ? contentTypeValueObject : content,
        };

        return (
            <>
                {showSkeleton && <GenericSkeleton height={skeleton_height} width={skeleton_width} id={sfContentType} maxWidth="unset" margin="0 auto" />}
                <HomePageBlock
                    block={block}
                    ssrDeviceType={ssrDeviceType}
                    presentationFamily={presentationFamily}
                    brand={brand}
                    hasTopMargin={hasTopMargin}
                    salesforceResponse={data?.contentSF}
                    setShowSkeleton={setShowSkeleton}
                    isFromPersonalizedContentBlock
                />
            </>
        );
    }

    return (
        <FallbackContentContainer
            skeleton_height={skeleton_height}
            skeleton_width={skeleton_width}
            fallbackUID={fallbackUID}
            contentType={contentType}
            hasTopMargin={hasTopMargin}
        />
    );
};

PersonalizedContentBlock.propTypes = {
    data: shape({
        content_types: array.isRequired,
    }).isRequired,
    ssrDeviceType: string,
    presentationFamily: string,
    brand: string,
    hasTopMargin: bool,
};

PersonalizedContentBlock.defaultProps = {
    ssrDeviceType: '',
    presentationFamily: '',
    brand: '',
    hasTopMargin: false,
};

export default PersonalizedContentBlock;
