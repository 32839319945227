/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    object, string, func,
} from 'prop-types';
import { useDispatch } from 'react-redux';

import ComponentErrorBoundary from '../../../EnterpriseID/Common/ComponentErrorBoundary/ComponentErrorBoundary';
import PersonalizedHPCarouselBuilder from '../PersonalizedHomePageCarouselBuilder/PersonalizedHomePageCarouselBuilder';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import noop from '../../../../../helpers/noop';
import { emitProductImpression, emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import PersonalizedHPGridUI from '../PersonalizedHomePageGridUI/PersonalizedHomePageGridUI';

const PersonalizedHPRecommendationsBuilder = ({
    data, products_to_show, customerRecsFallbackTitle = 'Recently Viewed Products', recommendationsType = 'recentlyViewedRecs', setShowSkeleton,
}) => {
    const dispatch = useDispatch();
    const dynamicSalesforceData = data?.[`${recommendationsType}`];

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: dynamicSalesforceData,
        isFireImpression: dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    const renderCarousel = (carouselTitle, products, productStyleConfig) => (
        <ComponentErrorBoundary function="PersonalizedHomePageSFContainer" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
            <PersonalizedHPCarouselBuilder
                blockData={{
                    productsToShow: products_to_show || {},
                    title: carouselTitle,
                    titleMargin: productStyleConfig?.title_styling?.title_margin,
                    titleFontColor: productStyleConfig?.title_styling?.title_font_color?.color,
                    titleFontSize: productStyleConfig?.title_styling?.title_font_size,
                    titleFontStyle: productStyleConfig?.title_styling?.title_font_style,
                    titleFontFamily: productStyleConfig?.title_styling?.title_font_family,
                    alignTitle: productStyleConfig?.title_styling?.align_title,
                    padding: productStyleConfig?.title_styling?.padding,
                }}
                productClickCallback={trackClickThrough}
                products={products}
                demandType="XS"
                productStyleConfig={productStyleConfig}
            />
        </ComponentErrorBoundary>
    );

    if ((dynamicSalesforceData?.products && dynamicSalesforceData?.products.length > 0)
        || (dynamicSalesforceData?.productRecs && dynamicSalesforceData?.productRecs.length > 0)) {
        const titleSF = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle || dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.fallbackProductRecTitle || dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.buyAgainRecTitle;
        const subTitle = dynamicSalesforceData?.campaign?.campaignResponses[0]?.payload?.productRecSubTitle || dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.buyAgainRecSubTitle;
        const displayType = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.displayType || '';
        const isGridView = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.layoutDisplayType === 'GRID';
        const minimumProductsToShow = dynamicSalesforceData?.campaign?.campaignResponses?.[0]?.payload?.minimumProductsToShow || 2;
        const products = dynamicSalesforceData?.products;
        const productRecs = dynamicSalesforceData?.productRecs;
        const productStylingConfig = dynamicSalesforceData?.productsContent?.personalized_product_card_reference?.length > 0 ? dynamicSalesforceData?.productsContent?.personalized_product_card_reference?.[0] : (dynamicSalesforceData?.productsContent || {});
        const productRecStylingConfig = dynamicSalesforceData?.productRecsContent?.personalized_product_card_reference?.length > 0 ? dynamicSalesforceData?.productRecsContent?.personalized_product_card_reference?.[0] : (dynamicSalesforceData?.productRecsContent || {});

        const {
            main_title, subtitle, show_subtitle, title_styling,
        } = productStylingConfig;

        const trackImpression = () => dispatch(emitCustomTrackingEvent({
            action: 'Impression',
            salesforceResponse: dynamicSalesforceData,
            page: { type: 'home' },
            eventSubType: 'campaign-tracking',
            displayType,
            minimumProductsToShow,
        }));

        switch (displayType) {
            case 'BOTH':
                trackImpression();
                if (products?.length >= minimumProductsToShow) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: dynamicSalesforceData,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView) {
                    return (
                        <ComponentErrorBoundary function="PersonalizedHPGridUI" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
                            <PersonalizedHPGridUI
                                sfData={data}
                                cmsData={{
                                    ...title_styling, title: titleSF, subTitle: show_subtitle ? subtitle : '', min_products_to_show: minimumProductsToShow,
                                }}
                                isNewDesign={false}
                                productClickCallback={trackClickThrough}
                                productStyleConfig={productStylingConfig}
                                productRecStyleConfig={productRecStylingConfig}
                                recommendationsType={recommendationsType}
                            />
                        </ComponentErrorBoundary>
                    );
                }
                return (
                    <>
                        {renderCarousel(main_title || customerRecsFallbackTitle, products?.length >= minimumProductsToShow ? products : [], productStylingConfig)}
                        {renderCarousel(titleSF || productRecStylingConfig?.main_title, productRecs, productRecStylingConfig)}
                    </>
                );

            case 'CUST_PRODUCTS_ONLY':
                trackImpression();
                if (products?.length >= minimumProductsToShow) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: dynamicSalesforceData,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView) {
                    return (
                        <ComponentErrorBoundary function="PersonalizedHPGridUI" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
                            <PersonalizedHPGridUI
                                sfData={data}
                                cmsData={{
                                    ...title_styling, title: main_title, subTitle: subtitle, min_products_to_show: minimumProductsToShow,
                                }}
                                isNewDesign={false}
                                productClickCallback={trackClickThrough}
                                productStyleConfig={productStylingConfig}
                                productRecStyleConfig={null}
                                recommendationsType={recommendationsType}
                            />
                        </ComponentErrorBoundary>
                    );
                }
                return renderCarousel(main_title || customerRecsFallbackTitle, products?.length >= minimumProductsToShow ? products : [], productStylingConfig);

            case 'PRODUCT_RECS_ONLY':
                trackImpression();

                if (isGridView) {
                    return (
                        <ComponentErrorBoundary function="PersonalizedHPGridUI" module="PersonalizedContent" message="Something went wrong when displaying Personalized HP Recommendations from Salesforce">
                            <PersonalizedHPGridUI
                                sfData={data}
                                cmsData={{
                                    ...title_styling, title: titleSF, subTitle, min_products_to_show: minimumProductsToShow,
                                }}
                                isNewDesign={false}
                                productClickCallback={trackClickThrough}
                                productStyleConfig={null}
                                productRecStyleConfig={productRecStylingConfig}
                                recommendationsType={recommendationsType}
                            />
                        </ComponentErrorBoundary>
                    );
                }
                return renderCarousel(titleSF || productRecStylingConfig?.main_title, productRecs, productRecStylingConfig);

            default:
                return null;
        }
    }
    return null;
};

PersonalizedHPRecommendationsBuilder.propTypes = {
    data: object.isRequired,
    products_to_show: object,
    recommendationsType: string.isRequired,
    customerRecsFallbackTitle: string.isRequired,
    setShowSkeleton: func,
};

PersonalizedHPRecommendationsBuilder.defaultProps = {
    products_to_show: {},
    setShowSkeleton: noop,
};

export default PersonalizedHPRecommendationsBuilder;
