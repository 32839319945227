/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import {
    object, string, func, shape,
} from 'prop-types';
import { useDispatch } from 'react-redux';

import ComponentErrorBoundary from '../Common/ComponentErrorBoundary/ComponentErrorBoundary';
import SimpleCarouselBuilder from '../Common/SimpleCarouselBuilder';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import noop from '../../../../helpers/noop';
import { emitProductImpression, emitCustomTrackingEvent } from '../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import BuyItAgainRecsGridUI from './BuyItAgainRecsGridUI/BuyItAgainRecsGridUI';

const BuyItAgainRecsBuilder = ({
    data, blockObj, setShowSkeleton,
}) => {
    const dispatch = useDispatch();

    const {
        products_to_show,
        min_products_to_show,
        buy_again_title,
        css_classes,
        title_margin,
        title_font_color,
        title_font_size,
        title_font_family,
        title_font_style,
        align_title = 'center',
        padding,
    } = blockObj || {};

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse: data,
        isFireImpression: data?.campaign?.campaignResponses?.[0]?.experienceName === 'Control',
        page: { type: 'home' },
    });

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    const renderCarousel = (carouselTitle, products) => (
        <ComponentErrorBoundary function="BuyItAgainRecsSFContainer" module="PersonalizedContent" message="Something went wrong when displaying Buy It Again Recommendations from Salesforce">
            <SimpleCarouselBuilder
                blockData={{
                    productsToShow: products_to_show,
                    title: carouselTitle,
                    css_classes,
                    titleMargin: title_margin,
                    titleFontColor: title_font_color?.color,
                    titleFontSize: title_font_size,
                    titleFontStyle: title_font_style,
                    titleFontFamily: title_font_family,
                    alignTitle: align_title,
                    padding,
                }}
                productClickCallback={trackClickThrough}
                products={products}
                demandType="XS"
            />
        </ComponentErrorBoundary>
    );

    const renderBuyAgainGridUI = (salesforceDataResponse, cmsBlockObj) => (
        <ComponentErrorBoundary function="BuyItAgainRecsGridUI" module="PersonalizedContent" message="Something went wrong when displaying Recently Viewed Recommendations from Salesforce">
            <BuyItAgainRecsGridUI sfData={salesforceDataResponse} cmsData={cmsBlockObj} isNewDesign={false} />
        </ComponentErrorBoundary>
    );

    if ((data?.buyAgainRecs?.products && data?.buyAgainRecs?.products.length > 0)
        || (data?.buyAgainRecs?.productRecs && data?.buyAgainRecs?.productRecs.length > 0)
    ) {
        const titleSF = data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.productRecTitle || data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.fallbackProductRecTitle;
        const displayType = data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.payload?.displayType || '';
        const products = data?.buyAgainRecs?.products;
        const productRecs = data?.buyAgainRecs?.productRecs;
        const isGridView = data?.buyAgainRecs?.campaign?.campaignResponses?.[0]?.experienceName === 'GRID';

        const trackImpression = () => dispatch(emitCustomTrackingEvent({
            action: 'Impression',
            salesforceResponse: data?.buyAgainRecs,
            page: { type: 'home' },
            eventSubType: 'campaign-tracking',
            displayType,
            min_products_to_show,
        }));

        switch (displayType) {
            case 'BOTH':
                trackImpression();
                if (products?.length >= min_products_to_show) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: data?.buyAgainRecs,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView) return renderBuyAgainGridUI(data, blockObj);

                return (
                    <>
                        {renderCarousel(titleSF, productRecs)}
                        {renderCarousel(buy_again_title || 'Buy It Again', products?.length >= min_products_to_show ? products : [])}
                    </>
                );

            case 'CUST_PRODUCTS_ONLY':
                trackImpression();
                if (products?.length >= min_products_to_show) {
                    dispatch(emitProductImpression({
                        action: 'Impression',
                        productsPayload: {
                            products,
                            mobileBreakpoint: 600,
                        },
                        salesforceResponse: data?.buyAgainRecs,
                        page: { type: 'home' },
                    }));
                }

                if (isGridView) return renderBuyAgainGridUI(data, blockObj);

                return renderCarousel(buy_again_title || 'Buy It Again', products?.length >= min_products_to_show ? products : []);

            case 'PRODUCT_RECS_ONLY':
                trackImpression();
                if (isGridView) return renderBuyAgainGridUI(data, blockObj);
                return renderCarousel(titleSF, productRecs);

            default:
                return null;
        }
    }
    return null;
};

BuyItAgainRecsBuilder.propTypes = {
    data: object.isRequired,
    blockObj: shape({
        title_font_color: shape({
            color: string.isRequired,
        }),
        title_font_size: string,
        title_font_style: string,
        title_font_family: string,
        padding: string,
    }).isRequired,
    setShowSkeleton: func,
};

BuyItAgainRecsBuilder.defaultProps = {
    setShowSkeleton: noop,
};

export default BuyItAgainRecsBuilder;
