/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';

const findBuyItAgainRecsSF = (queryName = 'BuyItAgainRecommendations') => (
    gql`query ${queryName}(
      $brand: String!,
      $environment: String!,
      $targeting: TargetingSF!
      $user: UserSF!
      $isHoldoutEligible: Boolean!
      $resolveIdentity: Boolean!
    ) {
      buyAgainRecs(
        brand: $brand,
        environment: $environment,
        targeting: $targeting,
        user: $user,
        isHoldoutEligible: $isHoldoutEligible
        resolveIdentity: $resolveIdentity
    ) {
      campaign
      products {
        partNumber
        name
        isPassportEligible
        image {
          name
          path
        }
        seo {
          url
        }
        skuPriceRange {
          retail {
            value
          }
          sale {
            value
          }
        }
        productSkus {
          partNumber
          name
        }
      }
      productRecs {
        partNumber
        name
        isPassportEligible
        image {
          name
          path
        }
        seo {
          url
        }
        skuPriceRange {
          retail {
            value
          }
          sale {
            value
          }
        }
        productSkus {
          partNumber
          name
        }
      }
      seedProduct {
        partNumber
        name
        image {
          name
          path
        }
        seo {
          url
        }
      }
      productsContent
      productRecsContent
      isHoldout
    }
    }
  `
);

export default findBuyItAgainRecsSF;
