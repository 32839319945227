/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useState } from 'react';
import mbpLogger from 'mbp-logger';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import {
    setDialogOpen, setDialogType, setOfferGatewayCTALabel, setOfferGatewayData,
} from '../../../../../../state/ducks/App/App-Actions';
import { useAuth } from '../../../../../../state/ducks/Member/ducks/Auth/Plugin/auth';
import { emitCustomTrackingEvent } from '../../../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';

function useOfferGatewayClickHandler(
    salesforceResponse,
) {
    const [isPromoCopied, setIsPromoCopied] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const { loginByRedirect } = useAuth();

    const campaignPayload = salesforceResponse?.campaign?.campaignResponses?.[0]?.payload || {};
    const isOfferUnlocked = campaignPayload.offerFlow === 'Offer Unlocked';
    const isAuthFlow = campaignPayload?.offerFlow === 'Login' || campaignPayload?.offerFlow === 'Account Creation';

    const handleAuthRoute = async () => {
        if (campaignPayload?.offerFlow === 'Login') {
            await loginByRedirect({ 'ext-offer-gateway-flow': 'yes' }, { routeBack: location.pathname, register: 'N' });
        } else if (campaignPayload?.offerFlow === 'Account Creation') {
            await loginByRedirect({ 'ext-offer-gateway-flow': 'yes' }, { routeBack: location.pathname, register: 'Y' });
        }
    };

    const copyPromoCode = async (textToCopy) => {
        try {
            if (typeof navigator !== 'undefined' && navigator.clipboard) {
                await navigator.clipboard.writeText(textToCopy);
                setIsPromoCopied(true);
            } else if (typeof document !== 'undefined') {
                // for old browsers
                const textArea = document.createElement('textarea');
                textArea.value = textToCopy;
                textArea.style.position = 'fixed';
                textArea.style.opacity = '0';
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy'); // depricated
                document.body.removeChild(textArea);
                setIsPromoCopied(true);
            }
        } catch (error) {
            mbpLogger.logError({
                function: 'OfferGatewayMessageStandard',
                message: 'Error while coping promo in offer gateway',
                appName: process.env.npm_package_name,
                module: 'Offer Gateway',
                jsError: error,
            });
        }
    };

    const handleOfferFlow = (button) => {
        if (!isOfferUnlocked) {
            dispatch(emitCustomTrackingEvent({
                action: 'Click',
                eventSubType: 'campaign-tracking',
                page: { type: 'home' },
                salesforceResponse,
            }));
            dispatch(setOfferGatewayData({
                salesforceResponse,
            }));

            // Handle login or account creation flow
            handleAuthRoute();
        } else if (button?.offer_status === 'copy') {
            copyPromoCode(campaignPayload?.offerBannerCode);
        }
    };

    const handleButtonClick = ({ event, item }) => {
        event?.stopPropagation();

        if (!isAuthFlow && item?.cta_button?.launch_modal) {
            // avoid launching modal if user is in auth flow
            event?.preventDefault();
            dispatch(setDialogOpen(true));
            dispatch(setDialogType('offerGateway'));
            dispatch(setOfferGatewayCTALabel(item?.cta_button?.tracking?.tracking_event_label));
        }

        handleOfferFlow(item);
    };

    const handleBannerClick = () => {
        if (isOfferUnlocked) return;

        if (!isAuthFlow && salesforceResponse?.content?.message?.custom_button?.[0]?.cta_button?.launch_modal) {
            dispatch(setDialogOpen(true));
            dispatch(setDialogType('offerGateway'));
            dispatch(setOfferGatewayCTALabel(salesforceResponse.content.message.custom_button[0].cta_button.tracking?.tracking_event_label));
        }

        handleOfferFlow();
    };

    return { handleBannerClick, handleButtonClick, isPromoCopied };
}

export default useOfferGatewayClickHandler;
