/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import {
    shape, string, bool, array,
} from 'prop-types';

// eslint-disable-next-line import/no-cycle
import GenericSkeleton from '../../GraphqlSkeletonComponents/GenericSkeleton';
// eslint-disable-next-line import/no-cycle
import HomePageBlock from '../../GraphqlHomePage/HomePageBlock';
// eslint-disable-next-line import/no-cycle
import FallbackContentContainer from '../Common/FallbackContentContainer';
import { getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import useSalesforceOfferGatewayQuery from '../../../../helpers/Personalization/useSalesforceOfferGatewayQuery';
import cookies from '../../../../helpers/common/storage/cookies';
import useSalesforcePersonalizedContentQuery from '../../../../helpers/Personalization/useSalesforcePersonalizedContentQuery';
import useClickStreamCustomEventsTracking from '../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import OfferGatewayBannerTall from '../../GraphqlCommonComponents/GenericDialog/OfferGateway/OfferGatewayBannerTall';

const OfferGatewayQuery = ({
    data: {
        content_type: contentType = '',
        pz_campaign_id: interactionName = '',
        content_zones,
        skeleton_height,
        skeleton_width,
        skeleton_margin = '0 auto',
        fallback_uid: fallbackUID = '',
    },
    ssrDeviceType,
    presentationFamily,
    brand,
    hasTopMargin,
    isCategoryPage,
}) => {
    const [showSkeleton, setShowSkeleton] = useState(true);
    // setShowSkeleton is used to show skeleton, until our components in homepageblock loads.

    const persistedUserProfile = cookies.get('persisted_user_profile');

    const targeting = {
        pageType: 'home',
    };
    if (content_zones?.length && content_zones?.[0]) {
        targeting.contentZones = content_zones;
    }

    const {
        loading, error, data, variables,
    } = useSalesforceOfferGatewayQuery({
        queryName: 'OfferGateway',
        interactionName,
        targeting,
        isSessionHasAccount: !!persistedUserProfile,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    if (loading || variables?.skip) {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} />;
    }

    if (error) {
        mbpLogger.logError({
            function: 'OfferGatewayQuery',
            message: `Error loading API data content_types = ${contentType.toString()} interactionName = ${interactionName}`,
            appName: process.env.npm_package_name,
            module: 'OfferGatewayBlock',
            jsError: error,
        });
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
                hasTopMargin={hasTopMargin}
            />
        );
    }

    if (data?.offerGatewayCampaignSF?.content) {
        const { campaign, content } = data.offerGatewayCampaignSF;
        const sfContentType = campaign?.campaignResponses?.[0]?.payload?.cmsContentType || campaign?.campaignResponses?.[0]?.payload?.promotions?.[0]?.attributes?.cmsContentType?.value;

        if (!sfContentType || !contentType.includes(sfContentType)) {
            mbpLogger.logError({
                function: 'OfferGatewayQuery',
                message: `Salesforce content_type not matching with CMS contentType entry  - salesforce content_type = ${sfContentType} is not matching with CMS contentType entry = ${contentType.toString()}`,
                appName: process.env.npm_package_name,
                module: 'OfferGatewayBlock',
            });
            return null;
        }

        const block = {
            [sfContentType]: content,
        };

        return (
            <>
                {showSkeleton && <GenericSkeleton height={skeleton_height} width={skeleton_width} id={sfContentType} maxWidth="unset" margin="0 auto" />}

                {isCategoryPage ? ( // determines if renders in homepage or category page
                    <OfferGatewayBannerTall
                        blockObj={content}
                        salesforceResponse={data.offerGatewayCampaignSF}
                        setShowSkeleton={setShowSkeleton}
                        hasTopMargin={hasTopMargin}
                        bannerHeight={skeleton_height}
                        bannerWidth={skeleton_width}
                    />
                ) : (
                    <HomePageBlock
                        block={block}
                        ssrDeviceType={ssrDeviceType}
                        presentationFamily={presentationFamily}
                        brand={brand}
                        hasTopMargin={hasTopMargin}
                        salesforceResponse={data?.offerGatewayCampaignSF}
                        setShowSkeleton={setShowSkeleton}
                        skeleton_height={skeleton_height}
                        skeleton_width={skeleton_width}
                    />
                )}
            </>
        );
    }

    return (
        <FallbackContentContainer
            skeleton_height={skeleton_height}
            skeleton_width={skeleton_width}
            fallbackUID={fallbackUID}
            contentType={contentType}
            hasTopMargin={hasTopMargin}
            salesforceResponse={data?.offerGatewayCampaignSF}
        />
    );
};

OfferGatewayQuery.propTypes = {
    data: shape({
        content_type: string.isRequired,
    }).isRequired,
    ssrDeviceType: string,
    presentationFamily: string,
    brand: string,
    hasTopMargin: bool,
    isCategoryPage: bool,
};

OfferGatewayQuery.defaultProps = {
    ssrDeviceType: '',
    presentationFamily: '',
    brand: '',
    hasTopMargin: false,
    isCategoryPage: false,
};

const OfferGatewayBlock = ({
    data: {
        content_type: contentType = '',
        wrapper_campaign_id,
        content_zones,
        skeleton_height,
        skeleton_width,
        skeleton_margin = '0 auto',
        fallback_uid: fallbackUID = '',
    },
    data,
    ssrDeviceType,
    presentationFamily,
    brand,
    hasTopMargin,
}) => {
    const isBot = useSelector(getIsBot);

    if (isBot) {
        return (
            <FallbackContentContainer
                skeleton_height={skeleton_height}
                skeleton_width={skeleton_width}
                fallbackUID={fallbackUID}
                contentType={contentType}
                hasTopMargin={hasTopMargin}
            />
        );
    }

    if (typeof window === 'undefined') {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} id="OfferGatewayBlock" />;
    }

    if (!wrapper_campaign_id) {
        return (
            <OfferGatewayQuery
                data={data}
                ssrDeviceType={ssrDeviceType}
                presentationFamily={presentationFamily}
                brand={brand}
                hasTopMargin={hasTopMargin}
            />
        );
    }

    const targeting = {
        pageType: 'home',
    };
    if (content_zones?.length && content_zones?.[0]) {
        targeting.contentZones = content_zones;
    }

    const {
        data: wrapperData, loading, error,
    } = useSalesforcePersonalizedContentQuery({
        queryName: 'OfferGatewayWrapper',
        interactionName: wrapper_campaign_id,
        targeting,
        optInHoldoutParticipation: true,
        resolveIdentity: true,
    });

    useClickStreamCustomEventsTracking({
        salesforceResponse: wrapperData?.contentSF,
        isFireImpression: true,
    });

    if (loading) {
        return <GenericSkeleton height={skeleton_height} width={skeleton_width} maxWidth="unset" margin={skeleton_margin} />;
    }

    if (error) {
        mbpLogger.logError({
            function: 'OfferGatewayBlock',
            message: 'Error loading API data from wrapper campaign',
            appName: process.env.npm_package_name,
            module: 'OfferGatewayBlock',
            jsError: error,
        });
    }

    if (wrapperData?.contentSF) {
        const userGroup = wrapperData?.contentSF?.campaign?.campaignResponses?.[0]?.payload?.userGroup || 'control';
        const isOfferGatewayEnabled = userGroup.toLowerCase() !== 'control';

        if (isOfferGatewayEnabled) {
            return (
                <OfferGatewayQuery
                    data={data}
                    ssrDeviceType={ssrDeviceType}
                    presentationFamily={presentationFamily}
                    brand={brand}
                    hasTopMargin={hasTopMargin}
                />
            );
        }
    }

    return null;
};

OfferGatewayBlock.propTypes = {
    data: shape({
        content_type: string.isRequired,
    }).isRequired,
    ssrDeviceType: string,
    presentationFamily: string,
    brand: string,
    hasTopMargin: bool,
};

OfferGatewayBlock.defaultProps = {
    ssrDeviceType: '',
    presentationFamily: '',
    brand: '',
    hasTopMargin: false,
};

export default OfferGatewayBlock;
