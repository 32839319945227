/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
    object, string, func, bool,
} from 'prop-types';
import ReactHTMLParser from 'react-html-parser';

import { parseFont } from '../../../../../../helpers/common/helper';
import BlockHeading from '../../../../../ContentTypeComponents/BlockHeading';
import noop from '../../../../../../helpers/noop';

const useStyles = makeStyles((theme) => ({
    heading: (styles) => (
        {
            flex: '1 1 50%',
            display: 'flex',
            justifyContent: styles?.textAlign || 'center',
            color: styles?.color,
            fontStyle: styles?.fontStyle,
            padding: '32px 0px 24px 0px',
            backgroundColor: styles?.backgroundColor,
            '& h2': {
                fontFamily: parseFont(styles?.fontFamily) || theme?.typography?.fontFamily,
                fontSize: styles?.fontSize || '28px',
                textAlign: styles?.textAlign || 'center',
                fontWeight: '600',
                lineHeight: '35px',
                letterSpacing: '0.01em',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
                [theme.breakpoints.down(600)]: {
                    fontSize: '21px',
                    lineHeight: '28px',
                },
            },
            '& span': {
                fontSize: styles?.fontSize1 || '18px',
                fontFamily: parseFont(styles?.fontFamily1) || theme?.typography?.fontFamily,
                color: styles?.color1,
                fontStyle: styles?.fontStyle1,
            },
            [theme.breakpoints.down(600)]: {
                padding: '0px 0px 24px 0px',
                '& h2': {
                    fontSize: styles?.fontSize || '24px',
                },
            },
        }
    ),
    headingAlignment: (styles) => (
        {
            display: 'flex',
            justifyContent: styles?.textAlign || 'center',
            flex: '1 1 50%',
            '& h2': {
                margin: '0px 0px 4px 0px',
            },
        }),
}));

export default function Heading({
    title, subTitle, styles, showSubTitle,
}) {
    const classes = useStyles(styles);
    return (
        <div id="personalized-hp-heading-id" className={classes.heading}>
            <div>
                <div className={classes.headingAlignment}>
                    <BlockHeading heading={ReactHTMLParser(title)} />
                </div>
                <div className={classes.headingAlignment}>
                    {showSubTitle && subTitle && (<span id="personalized-subTitle-id">{ReactHTMLParser(subTitle)}</span>)}
                </div>
            </div>
        </div>
    );
}

Heading.propTypes = {
    title: string.isRequired,
    subTitle: string.isRequired,
    styles: object,
    showSubTitle: bool,
};

Heading.defaultProps = {
    styles: {},
    showSubTitle: false,
};
