/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    array, func, object, string,
} from 'prop-types';

import { makeStyles } from '@material-ui/core';
import { useUIDSeed } from 'react-uid';
import StyledButton from '../../../../EnterpriseDesignSystem/Button/StyledButton';
import replaceSpecialChar from '../../../../../helpers/replaceSpecialChar';
import noop from '../../../../../helpers/noop';

export default function OfferGatewayButton({
    ctaArray, buttonContainer, handleClick,
}) {
    const seed = useUIDSeed();

    return ctaArray?.map((item, index) => (
        <Button
            key={seed(item)}
            item={item}
            handleClick={handleClick}
            buttonContainer={buttonContainer}
        />
    ));
}

OfferGatewayButton.propTypes = {
    ctaArray: array.isRequired,
    buttonContainer: string.isRequired,
    handleClick: func,
};

OfferGatewayButton.defaultProps = {
    handleClick: noop,
};

const useStyles = makeStyles(() => ({
    buttonContainer: (styles) => ({
        marginTop: '12px',
        alignItems: 'end',
        '& button, a': {
            padding: 0,
            userSelect: styles.isSelectable ? 'text' : null,
            cursor: styles.isSelectable ? 'text' : null,
            '& span': {
                padding: styles.padding,
            },
            '& span[class*="button_icon"]': {
                backgroundColor: styles.iconBGColor,
                color: styles.iconColor,
                padding: styles.iconPadding,
                margin: 0,
                cursor: 'pointer',
            },
        },
    }),
    helperText: {
        margin: 0,
    },
}));

const Button = ({
    buttonContainer, item, handleClick,
}) => {
    const classes = useStyles({
        padding: item?.padding,
        iconPadding: item?.icon_padding,
        iconColor: item?.icon_color?.color,
        iconBGColor: item?.icon_bg_color?.color,
        isSelectable: item?.offer_status === 'copy',
    });

    const renderHelperText = () => {
        if (item?.is_helper_text) {
            return <p className={`${classes.helperText} text2`}> {replaceSpecialChar(item.helper_text, true)}</p>;
        }
        return <></>;
    };

    return (
        <div className={`${buttonContainer} ${classes.buttonContainer}`}>
            {renderHelperText(item)}
            <StyledButton
                label={item?.cta_button?.link?.title}
                link={item?.cta_button?.link?.href}
                variant={item?.cta_button?.variant}
                size={item?.cta_button?.size}
                attributes={item?.cta_button?.html_attributes || {}}
                handleOnClick={(event) => handleClick({ event, item })}
                onMouseUp={(event) => handleClick({ event, item })}
                onTouchEnd={(event) => handleClick({ event, item })}
                icon={item?.icon}
            />
        </div>
    );
};

Button.propTypes = {
    buttonContainer: string.isRequired,
    item: object.isRequired,
    handleClick: func,
};

Button.defaultProps = {
    handleClick: noop,
};
