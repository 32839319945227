/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import {
    object,
} from 'prop-types';
import mbpLogger from 'mbp-logger';
import useStyles from 'isomorphic-style-loader/useStyles';
import styles from './LinkList.module.css';
import LinkOrATag from '../../../GraphqlCommonComponents/LinkOrATag/LinkOrATag';

const LinkListBanner = ({
    data,
}) => {
    useStyles(styles);
    if (!Object.keys(data)?.length) {
        return mbpLogger.logError({
            appName: process.env.npm_package_name,
            component: 'SubComponents/LinkListBanner/LinkListBanner.js',
            message: 'No data available to render component',
        });
    }
    const position = data.link_list?.position || 'row';
    const trackEventData = data.link_list?.ga_constants;
    return (
        <div className={position === 'row' ? styles.rightListContainer : styles.listContainer}>
            {data.link_list?.link?.map((item) => (
                item?.link?.href
                    ? (
                        <LinkOrATag
                            isTrackEventEnabled
                            href={item?.link.href}
                            trackingEventCategory={trackEventData?.tracking_event_category || ''}
                            trackingEventAction={trackEventData?.tracking_event_action || ''}
                            trackingEventLabel={item?.tracking_event_label || ''}
                            className={styles.link}
                        >
                            {item?.link?.title}
                        </LinkOrATag>
                    )
                    : <span className={styles.link}>{item?.link?.title}</span>
            ))}
        </div>
    );
};

LinkListBanner.propTypes = {
    data: object.isRequired,
};
export default LinkListBanner;
